const ChartTooltip = ({ color, children }) => {
  return (
    <div
      style={{
        background: '#fff',
        padding: '1rem',
        border: '1px solid #ccc',
        display: 'block',
        width: '100%'
      }}
    >
      {color && (
        <i
          style={{
            backgroundColor: color,
            width: '0',
            height: '0',
            padding: '.6rem',
            marginRight: '.6rem',
            display: 'inline-block',
            position: 'relative',
            top: '.3rem'
          }}
        />
      )}
      {children}
    </div>
  )
}

export default ChartTooltip
