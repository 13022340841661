import moment from 'moment'
import { capitalize } from 'utils/helpers'

export const getTimeFilterName = timeFilter => {
  if (!timeFilter) return ''

  const { selectedTimeFilterOption, isTimeFilterActive, startDate, endDate } = timeFilter
  if (!selectedTimeFilterOption || !isTimeFilterActive || isTimeFilterActive !== 'true') {
    return 'Today'
  }

  const formattedStartDate = moment(startDate).format('DD MMMM')
  const formattedEndDate = moment(endDate).format('DD MMMM')

  switch (selectedTimeFilterOption) {
    case 'lastWeek':
      return 'Last 7 days'
    case 'lastMonth':
      return 'Last 30 days'
    case 'custom':
      return `${formattedStartDate} / ${formattedEndDate}`
    default:
      return capitalize(selectedTimeFilterOption)
  }
}

export const getLocationHeader = selectedFilters => {
  if (!selectedFilters.locationFilter) return ''

  const { regions, stores, isLocationFilterActive } = selectedFilters.locationFilter

  if (!isLocationFilterActive || isLocationFilterActive !== 'true' || !regions.length) {
    return 'All regions'
  }

  const regionsString = regions.map(region => region.label).join('/')
  const storesString = stores?.length ? stores.map(store => store.label).join(', ') : ''

  let result = regionsString
  if (storesString) {
    result = `${result} / ${storesString}`
  }

  return result
}

export const getTimeInterval = selectedTimeFilterOption => {
  switch (selectedTimeFilterOption) {
    case 'today':
      return {
        startDate: moment(Date.now())
          .startOf('day')
          .toDate(),
        endDate: moment(Date.now()).toDate()
      }
    case 'yesterday':
      return {
        startDate: moment(Date.now())
          .subtract(1, 'days')
          .toDate(),
        endDate: moment(Date.now()).toDate()
      }
    case 'lastWeek':
      return {
        startDate: moment(Date.now())
          .subtract(1, 'weeks')
          .toDate(),
        endDate: moment(Date.now()).toDate()
      }
    case 'lastMonth':
      return {
        startDate: moment(Date.now())
          .subtract(1, 'months')
          .toDate(),
        endDate: moment(Date.now()).toDate()
      }
    default:
      return {
        startDate: '',
        endDate: ''
      }
  }
}
