import { ResponsivePie } from '@nivo/pie'
import { ChartTooltip } from 'components/common/ChartTooltip'

const keys = ['percentage']

const PieChart = ({ data, setSelectedStatus, setIsDrawersModalVisible }) => {
  const chartStatusClick = node => {
    const selectedStatus = node.data.id
    setSelectedStatus(selectedStatus)
    setIsDrawersModalVisible(true)
  }

  return (
    <ResponsivePie
      data={data}
      keys={keys}
      indexBy="status"
      margin={{ top: 10, right: 0, bottom: 20, left: 100 }}
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={0}
      activeOuterRadiusOffset={8}
      borderWidth={0}
      borderColor={{
        from: 'color',
        modifiers: [['darker', 0.2]]
      }}
      colors={{ datum: 'data.color' }}
      enableArcLinkLabels={false}
      enableArcLabels={false}
      onClick={chartStatusClick}
      legends={[
        {
          anchor: 'top-left',
          direction: 'column',
          justify: false,
          translateX: -80,
          translateY: 30,
          itemsSpacing: 10,
          itemWidth: 100,
          itemHeight: 18,
          itemTextColor: '#999',
          itemDirection: 'left-to-right',
          itemOpacity: 1,
          symbolSize: 18,
          symbolShape: 'circle',
          effects: [
            {
              on: 'hover',
              style: {
                itemTextColor: '#000'
              }
            }
          ]
        }
      ]}
      animate
      motionStiffness={90}
      motionDamping={15}
      tooltip={({ datum }) => (
        <ChartTooltip color={datum.color}>
          {datum.data.status}: <strong>{datum.value}%</strong>
        </ChartTooltip>
      )}
    />
  )
}

export default PieChart
