import { ResponsivePie } from '@nivo/pie'
import { ChartTooltip } from 'components/common/ChartTooltip'
import cn from 'classnames'
import s from './ShelfPieChart.scss'

const ShelfPieChart = ({ data, shelfName, isActive }) => {
  return (
    <div className={cn(s.pieChartContent, { [s.active]: isActive })}>
      <ResponsivePie
        data={data}
        margin={{ top: 10, right: 0, bottom: 20, left: 0 }}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={0}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        borderColor={{
          from: 'color',
          modifiers: [['darker', 0.2]]
        }}
        colors={{ datum: 'data.color' }}
        enableArcLinkLabels={false}
        enableArcLabels={false}
        animate
        motionStiffness={90}
        motionDamping={15}
        tooltip={({ datum }) => (
          <ChartTooltip color={datum.color}>
            <strong className={s.tooltipInfo}>
              {datum.value}% ({datum.id})
            </strong>
          </ChartTooltip>
        )}
      />
      <strong>{shelfName}</strong>
    </div>
  )
}

export default ShelfPieChart
