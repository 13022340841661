export const toItemsValues = (selectedItems, allItems) =>
  selectedItems
    ? selectedItems.map(item => allItems.filter(i => i.value.toString() === item.toString())[0])
    : []

export const toRegionsSelect = regions =>
  regions.map(region => ({
    value: region.regionUid,
    label: region.regionName
  }))

export const toRegionSelect = region => ({
  value: region.regionUid,
  label: region.regionName
})

export const toStoresSelect = stores =>
  stores.map(store => ({ value: store.locationUid, label: store.locationName }))
