import { useState, useContext } from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { FiltersContext } from 'components/Layout/LayoutContainer'
import { makePostRequest } from 'utils/api'
import { exportFile } from './helpers'
import s from './ReportActions.scss'

const ReportActions = ({ selectedReportId, departmentUid, requestFilters, hideReportModal }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const filtersContext = useContext(FiltersContext)

  const open = Boolean(anchorEl)

  const handleClickDownload = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuItemClick = async fileFormat => {
    const { dateIntervals, regionUids, locationUids } = requestFilters
    const { start, end } = dateIntervals[1]
    const url = `reporting/v1.0.0-alpha/reports/exportReport/${selectedReportId}`
    const params = {
      departmentUid: departmentUid,
      dateStart: start,
      dateEnd: end,
      regionUids,
      locationUids,
      fileFormat
    }

    const response = await makePostRequest(url, params, { responseType: 'blob' }, true)
    exportFile(response)
    handleClose()
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div className={s.rpActions}>
      <a
        className={s.prEdit}
        onClick={() => {
          hideReportModal()
          filtersContext.setViewFilters(true)
        }}
      >
        <i className="icon-edit1" />
      </a>
      <a className={s.prShare}>
        <i className="icon-share1" />
      </a>
      <button className={s.prDowload} onClick={handleClickDownload}>
        <i className="icon-folder-upload"></i>
      </button>
      <Menu
        className={s.downloadReportMenu}
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox'
        }}
      >
        <MenuItem onClick={() => handleMenuItemClick('csv')}>Download as CSV</MenuItem>
        <MenuItem onClick={() => handleMenuItemClick('xls')}>Download as Excel</MenuItem>
      </Menu>
    </div>
  )
}

export default ReportActions
