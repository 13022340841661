import Select from 'react-select'
import s from '../LocationFilters.scss'

const RegionsFilter = ({ regions, setFilterValue, selectedRegion, position, styles }) => {
  return (
    <Select
      onChange={value =>
        value
          ? setFilterValue('regions', { ...value, position })
          : setFilterValue('regions', { value, position })
      }
      options={regions}
      value={selectedRegion}
      className={s.select}
      placeholder="All Regions"
      styles={styles}
      isClearable
    />
  )
}

export default RegionsFilter
