import filtersReducer from 'components/common/Filters/reducer'
import retailerReducer from 'components/Home/reducer'
import appReducer from 'components/App/reducer'

const reducers = {
  filters: filtersReducer,
  retailer: retailerReducer,
  app: appReducer
}

export default reducers
