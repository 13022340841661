import { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper'
import { Report } from '../common/Report'
import { Button } from 'components/rbc-core-ui'
import cn from 'classnames'
import s from './Reports.scss'

const Reports = ({
  filters,
  requestFilters,
  reportTypes,
  selectedReportType,
  selectReportType,
  isReportVisible,
  openReport,
  closeReport,
  showFilters,
  setShowFilters
}) => {
  const [viewContinueBtn, setViewContinueBtn] = useState(false)

  return (
    <div className={cn(s.createReportContainer, { [s.viewAfterReportPreset]: viewContinueBtn })}>
      <div className={s.sectionContentHeader}>
        <h2>Create Your Report</h2>
        <h6>Choose a Preset</h6>
      </div>

      <div className={cn(s.reportPresetSlider, 'report-preset-slider')}>
        <Swiper
          modules={[Pagination]}
          speed={700}
          slidesPerView={3}
          slidesPerGroup={3}
          spaceBetween={12}
          preventClicks={false}
          preventClicksPropagation={false}
          pagination={{
            clickable: true
          }}
          breakpoints={{
            // when window width is >= 320px
            220: {
              slidesPerView: 3,
              slidesPerGroup: 3
            },
            // when window width is >= 960px
            960: {
              slidesPerView: 3,
              slidesPerGroup: 3,
              centeredSlides: false
            }
          }}
        >
          {reportTypes.map(report => (
            <SwiperSlide
              key={report.name.replace(' ', '_')}
              className={s.swiperSlide}
              onClick={() => {
                setViewContinueBtn(true)
                selectReportType(report)
              }}
            >
              <input
                type="radio"
                name="departaments"
                checked={selectedReportType.id === report.id}
                readOnly
              />
              <label>
                <div
                  className={s.reportIcon}
                  style={{ backgroundImage: `url(${report.iconUrl})` }}
                />
                <strong>{report.name}</strong>
              </label>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      {selectedReportType.type && (
        <Report
          isVisible={isReportVisible}
          closeReport={() => {
            setViewContinueBtn(false)
            closeReport()
          }}
          selectedReport={selectedReportType}
          filters={filters}
          requestFilters={requestFilters}
          showFilters={showFilters}
          setShowFilters={setShowFilters}
        />
      )}
      <div className={s.beforeReportPreset}>
        <p className={s.infoTxt}>
          Please consider configuring the filters below before generating the report!
        </p>
        <span className={s.indicatorArrow} />
      </div>
      <div className={s.afterReportPreset}>
        <Button
          text="Preview Report"
          onClickCb={openReport}
          withIcon
          iconClassName="icon-arrow-next-1"
        />
      </div>
    </div>
  )
}

export default Reports
