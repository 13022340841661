import * as ACT from './actions'
import { createReducer } from 'redux-create-reducer'
import update from 'immutability-helper'

const initialState = {
  retailerUid: '1',
  retailerName: '',
  departments: []
}

const retailerReducer = createReducer(initialState, {
  [ACT.SET_RETAILER](state, action) {
    const { retailer } = action.payload

    return {
      ...state,
      ...retailer
    }
  },

  [ACT.SET_DEPARTMENTS](state, action) {
    const { departments } = action.payload

    return update(state, {
      departments: { $set: departments }
    })
  }
})

export default retailerReducer
