import { TimeFilters } from './TimeFilters'
import { LocationFilters } from './LocationFilters'
import { DepartmentFilters } from './DepartmentFilters'
import { getTimeFilterName } from '../helpers'
import s from './FiltersList.scss'

const FiltersList = ({
  selectedFilters,
  setFilterValue,
  customIntervalVisible,
  setCustomIntervalVisible,
  regions,
  stores,
  departments,
  locationHeader
}) => {
  const toggleTimeFilters = () => {
    const value = selectedFilters.timeFilter.isTimeFilterActive === 'true' ? 'false' : 'true'
    setFilterValue('isTimeFilterActive', value)
  }

  const toggleLocationFilters = () => {
    const value =
      selectedFilters.locationFilter.isLocationFilterActive === 'true' ? 'false' : 'true'
    setFilterValue('isLocationFilterActive', value)
  }

  return (
    <ul className={s.filterList}>
      <li>
        <div onClick={toggleTimeFilters}>
          <div className={s.swBox}>
            <input
              type="checkbox"
              name="swb"
              checked={selectedFilters.timeFilter?.isTimeFilterActive === 'true'}
            />
            <label className={s.label}></label>
          </div>
          <h4>
            <span>1.</span>
            <strong>
              Change time interval
              <em>{getTimeFilterName(selectedFilters.timeFilter)}</em>
            </strong>
          </h4>
        </div>
        <TimeFilters
          isVisible={selectedFilters.timeFilter?.isTimeFilterActive === 'true'}
          selectedFilters={selectedFilters.timeFilter}
          setFilterValue={setFilterValue}
          customIntervalVisible={customIntervalVisible}
          setCustomIntervalVisible={setCustomIntervalVisible}
        />
      </li>
      <li>
        <div onClick={toggleLocationFilters}>
          <div className={s.swBox}>
            <input
              type="checkbox"
              name="swb"
              checked={selectedFilters.locationFilter?.isLocationFilterActive === 'true'}
            />
            <label className={s.label}></label>
          </div>
          <h4>
            <span>2.</span>
            <strong>
              Change location<em>{locationHeader}</em>
            </strong>
          </h4>
        </div>
        <LocationFilters
          isVisible={selectedFilters.locationFilter?.isLocationFilterActive === 'true'}
          selectedFilters={selectedFilters.locationFilter}
          setFilterValue={setFilterValue}
          regions={regions}
          stores={stores}
        />
      </li>
      <li>
        <div className={s.swBox}>
          <input type="checkbox" name="swb" disabled />
          <label className={s.label}></label>
        </div>
        <h4>
          <span>3.</span>
          <strong>
            Change department
            <em>{departments && departments.length ? departments[0]?.departmentName : ''}</em>
          </strong>
        </h4>
        <DepartmentFilters />
      </li>
    </ul>
  )
}

export default FiltersList
