import { useState, useCallback } from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { PieChart } from './PieChart'
import { BarChart } from './BarChart'
import cn from 'classnames'
import { BiDotsVerticalRounded } from 'react-icons/bi'
import { DrawersSlider } from 'components/common/DrawersSlider'
import { getInitialSlide } from './helpers'
import s from './StatusCharts.scss'

const StatusCharts = ({ pieChartData, barChartData, drawers }) => {
  const [selectedStatus, setSelectedStatus] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedIndex, setSelectedIndex] = useState(1)
  const [viewPieChart, setViewPieChart] = useState(true)
  const [isDrawersModalVisible, setIsDrawersModalVisible] = useState(false)

  const open = Boolean(anchorEl)

  const getOptions = useCallback(() => {
    return [`View as ${viewPieChart ? 'bar' : 'pie'} chart`]
  }, [viewPieChart])

  const handleClickListItem = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuItemClick = useCallback((index, option) => {
    setSelectedIndex(index)
    setAnchorEl(null)

    switch (option) {
      case 'View as bar chart':
        setViewPieChart(false)
        break
      case 'View as pie chart':
        setViewPieChart(true)
        break
      default:
        break
    }
  }, [])

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div className={s.chartContainer}>
      <div className={s.menuBtn} onClick={handleClickListItem}>
        <BiDotsVerticalRounded />
      </div>
      <Menu
        className={s.chartsMenu}
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox'
        }}
      >
        {getOptions().map((option, index) => (
          <MenuItem
            key={option}
            selected={index === selectedIndex}
            onClick={() => handleMenuItemClick(index, option)}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
      <section className={cn(s.box, 'box')}>
        <div className={s.boxHeader}>
          <h3>Drawers status</h3>
        </div>
        {viewPieChart ? (
          <div className={cn(s.boxBody, s.pieChartContent)}>
            <PieChart
              data={pieChartData}
              setSelectedStatus={setSelectedStatus}
              setIsDrawersModalVisible={setIsDrawersModalVisible}
            />
          </div>
        ) : (
          <div className={cn(s.boxBody, s.pieChartContent)}>
            <BarChart
              data={barChartData}
              setSelectedStatus={setSelectedStatus}
              setIsDrawersModalVisible={setIsDrawersModalVisible}
            />
          </div>
        )}
      </section>
      <DrawersSlider
        initialSlide={getInitialSlide(selectedStatus)}
        drawers={drawers}
        isVisible={isDrawersModalVisible}
        close={() => setIsDrawersModalVisible(false)}
      />
    </div>
  )
}

export default StatusCharts
