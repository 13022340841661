import s from './SplashScreen.scss'

const SplashScreen = () => {
  return (
    <div className="bluePageTpl">
      <section className={s.splashScreen}>
        <div className={s.splashScreenContent}>
          <img className={s.ssLogo} src="images/SYS-Brandmark-Logo-Splash.svg" />
          <h1>
            <strong>Smart</strong> Your Shelf
          </h1>
        </div>
        <p className={s.copy}>Developed by RBC</p>
      </section>
    </div>
  )
}

export default SplashScreen
