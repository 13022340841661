import { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Thumbs } from 'swiper'
import { StoreHeader } from './StoreHeader'
import { Shelves } from './Shelves'
import { makeGetRequest } from 'utils/api'
import { toStore } from './serializers'
import { getRetailer } from 'utils/helpers'
import { PageTitle } from 'components/common/PageTitle'
import { Filters as GeneralFilter } from 'components/common/Filters'
import s from './Store.scss'
import cn from 'classnames'

const Store = () => {
  const retailer = getRetailer()

  let { id } = useParams()

  const [store, setStore] = useState(null)
  const [thumbsSwiper, setThumbsSwiper] = useState(null)

  const [menuVisible, setMenuVisible] = useState(false)

  useEffect(() => {
    ;(async () => {
      try {
        const result = await makeGetRequest(
          `reporting/v1.0.0-alpha/locations/getLocationDetails/${id}`
        )
        setStore(toStore(result))
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e)
      }
    })()
  }, [id])

  return (
    <>
      <PageTitle retailer={retailer?.retailerName} store={store?.name} />
      <div className={s.wrapper}>
        {store && (
          <>
            <StoreHeader store={store} menuState={[menuVisible, setMenuVisible]} />
            <Swiper
              className={cn(s.tabsNav, { [s.viewMenu]: menuVisible })}
              modules={[Thumbs]}
              watchSlidesProgress
              onSwiper={setThumbsSwiper}
              slidesPerView={3}
              spaceBetween={0}
              breakpoints={{
                220: {
                  slidesPerView: '3'
                },
                960: {
                  slidesPerView: 'auto'
                }
              }}
            >
              <SwiperSlide className={s.swiperSlide}>Shelves</SwiperSlide>
            </Swiper>
            <Swiper
              className={cn(s.tabsBody, { [s.viewMenu]: menuVisible })}
              modules={[Thumbs]}
              thumbs={{ swiper: thumbsSwiper }}
              spaceBetween={0}
              autoHeight={true}
              allowTouchMove={false}
              effect={'fade'}
              fadeEffect={{ crossFade: true }}
              preventClicks={false}
              preventClicksPropagation={false}
            >
              <SwiperSlide>
                <Shelves storeId={id} retailer={retailer} menuVisible={menuVisible} />
              </SwiperSlide>
            </Swiper>
          </>
        )}
      </div>
      <GeneralFilter withHeader className={cn(s.storeFilters, { [s.viewMenu]: menuVisible })} />
    </>
  )
}

export default Store
