import axios from 'axios'
import { URLS, URL } from 'utils/constants'
import { getToken } from './authService'
import { isTokenExpired } from './jwtHelper'
import { API_KEY } from 'utils/constants'

axios.interceptors.request.use(
  config => {
    const token = getToken()

    if (!config.headers.Authorization) {
      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }
    }

    if (isTokenExpired(token)) {
      if (window.location.pathname !== '/') {
        window.location.href = '/'
      }
    }
    config.headers[API_KEY.key] = API_KEY.value

    return config
  },
  error => Promise.reject(error)
)

export const getBaseUrl = url => {
  return `${URL[process.env.ENV]}${url}`
}

export const getRequest = url => {
  return axios.get(getBaseUrl(url)).then(response => response.data)
}

export const makeGetRequest = (url, params) => {
  return axios.get(getBaseUrl(url), params).then(response => response.data)
}

export const makePostRequest = (url, params, config = {}, withHeaders = false) =>
  axios
    .post(getBaseUrl(url), params, config)
    .then(response =>
      withHeaders ? { data: response.data, headers: response.headers } : response.data
    )

export const makePutRequest = (url, params) =>
  axios.put(getBaseUrl(url), params).then(response => response.data)

export const makeDeleteRequest = (url, params) =>
  axios.delete(getBaseUrl(url), params).then(response => response.data)

export const makePatchRequest = (url, params) =>
  axios.patch(getBaseUrl(url), params).then(response => response.data)

export const loginRedirect = navigate => {
  if (process.env.NODE_ENV === 'production') {
    window.location.href = '/'
  } else {
    navigate(URLS.AUTH_HOME)
  }
}
