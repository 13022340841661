import { useContext, useEffect, useState } from 'react'
import cn from 'classnames'
import { FiltersHeader } from './FiltersHeader'
import { FiltersList } from './FiltersList'
import { IoMdClose } from 'react-icons/io'
import s from './Filters.scss'
import { useImmer } from 'use-immer'
import { cloneDeep } from 'lodash'
import { getLocationHeader, getTimeInterval } from './helpers'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { makeGetRequest } from 'utils/api'
import { getRetailer } from 'utils/helpers'
import * as ACT from './actions'
import * as APP_ACT from 'actions/actionTypes'
import { Button } from 'components/rbc-core-ui'
import { FiltersContext } from 'components/Layout/LayoutContainer'
import { URLS } from 'utils/constants'
import isEmpty from 'lodash/isEmpty'

const Filters = ({ withHeader, className, setShowFilters }) => {
  const [selectedFilters, setSelectedFilters] = useImmer({})
  const [customIntervalVisible, setCustomIntervalVisible] = useState(false)
  const filtersContext = useContext(FiltersContext)

  const reduxDispatch = useDispatch()

  let navigate = useNavigate()

  const filters = useSelector(state => state.filters)
  const app = useSelector(state => state.app)

  useEffect(() => {
    reduxDispatch({
      type: ACT.INIT_FILTERS
    })
  }, [reduxDispatch])

  useEffect(() => {
    setSelectedFilters(filters)
  }, [filters, setSelectedFilters])

  useEffect(() => {
    if (!isEmpty(selectedFilters)) {
      setCustomIntervalVisible(selectedFilters.timeFilter.selectedTimeFilterOption === 'custom')
    }
  }, [selectedFilters])

  useEffect(() => {
    ;(async () => {
      const retailer = getRetailer()
      if (retailer.retailerUid) {
        const regions = await makeGetRequest(
          `reporting/v1.0.0-alpha/retailers/getRegionsHierarchy/${retailer.retailerUid}`
        )
        reduxDispatch({
          type: APP_ACT.SET_ALL_REGIONS,
          payload: {
            regions
          }
        })
      }
    })()
  }, [reduxDispatch])

  useEffect(() => {
    ;(async () => {
      let regionUid
      if (!isEmpty(selectedFilters) && selectedFilters.locationFilter.regions.length) {
        const { regions } = selectedFilters.locationFilter
        regionUid = regions[regions.length - 1].value
      }

      if (regionUid) {
        const retailer = getRetailer()
        const stores = await makeGetRequest(
          `reporting/v1.0.0-alpha/locations/getLocationsList/${retailer.retailerUid}?regionUid=${regionUid}`
        )
        reduxDispatch({
          type: APP_ACT.SET_ALL_STORES,
          payload: {
            stores
          }
        })
      }
    })()
  }, [reduxDispatch, selectedFilters])

  const setFilterValue = (property, value) => {
    let val = value
    if (property === 'regions') {
      val = cloneDeep(selectedFilters.locationFilter.regions) || []
      const existingIndex = val.findIndex(v => v.position === value.position)

      // existing select value changed
      if (~existingIndex) {
        // value for existing select has changed
        if (value.value) {
          val[existingIndex] = value
          val.splice(existingIndex + 1)
        } else {
          // value for existing select has been cleared
          val.splice(existingIndex)
        }
      } else {
        val.push(value)
      }

      if (selectedFilters.locationFilter.stores.length) {
        setSelectedFilters(state => {
          state.locationFilter.stores = []
        })
      }
    }

    if (property === 'selectedTimeFilterOption' && value !== 'custom') {
      const { startDate, endDate } = getTimeInterval(value)
      setSelectedFilters(state => {
        state.timeFilter.startDate = startDate
        state.timeFilter.endDate = endDate
      })
    }

    setSelectedFilters(state => {
      if (['regions', 'stores', 'isLocationFilterActive'].includes(property)) {
        state.locationFilter[property] = val
      }
      if (
        ['selectedTimeFilterOption', 'startDate', 'endDate', 'isTimeFilterActive'].includes(
          property
        )
      ) {
        state.timeFilter[property] = val
      }
    })
  }

  const saveFilters = () => {
    reduxDispatch({
      type: ACT.UPDATE_FILTERS,
      payload: { selectedFilters }
    })

    filtersContext.setViewFilters(false)
    setShowFilters && setShowFilters(false)

    const { stores, isLocationFilterActive } = selectedFilters.locationFilter

    if (isLocationFilterActive !== 'false' && stores.length === 1) {
      navigate(`/stores/${stores[0].value}`)
    } else {
      navigate(URLS.AUTH_DASHBOARD)
    }
  }

  const closeFilters = () => {
    filtersContext.setViewFilters(false)
    setCustomIntervalVisible(false)
    setShowFilters && setShowFilters(false)

    reduxDispatch({
      type: ACT.CANCEL_FILTERS
    })
  }

  const resetFilters = () => {
    filtersContext.setViewFilters(false)
    setCustomIntervalVisible(false)
    setShowFilters && setShowFilters(false)

    reduxDispatch({
      type: ACT.RESET_FILTERS
    })
  }

  const locationHeader = getLocationHeader(selectedFilters)

  return (
    <section
      className={cn(
        s.filters,
        { [s.viewFilters]: filtersContext.viewFilters },
        { [s.withHeader]: withHeader },
        className
      )}
    >
      {withHeader && (
        <FiltersHeader
          filtersVisible={filtersContext.viewFilters}
          onClick={() => {
            filtersContext.setViewFilters(true)
            setShowFilters && setShowFilters(false)
          }}
          selectedFilters={selectedFilters}
          locationHeader={locationHeader}
          departments={getRetailer()?.departments}
        />
      )}
      <div style={{ zIndex: '1031', overflowY: 'auto', height: '100%' }}>
        <div className={cn(s.squareButton, s.closeBox)} onClick={closeFilters}>
          <IoMdClose />
        </div>
        <div className={cn(s.boxBody, s.filtersBody, s.form)}>
          <FiltersList
            selectedFilters={selectedFilters}
            setFilterValue={setFilterValue}
            customIntervalVisible={customIntervalVisible}
            setCustomIntervalVisible={setCustomIntervalVisible}
            regions={app.allRegions}
            stores={app.allStores}
            departments={getRetailer()?.departments}
            locationHeader={locationHeader}
          />
          <div className={s.filtersBtns}>
            <Button
              text="Reset"
              onClickCb={resetFilters}
              withIcon
              iconClassName="icon-reset"
              transparent
              className={s.resetBtn}
            />
            <Button
              text="Apply"
              onClickCb={saveFilters}
              iconClassName="icon-arrow-next-1"
              withIcon
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Filters
