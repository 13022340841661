import { useNavigate } from 'react-router-dom'
import { URLS } from 'utils/constants'
import cn from 'classnames'
import s from './Drawer.scss'

const Drawer = ({ index, drawer, color }) => {
  const { fillPercentage, county, storeName, id } = drawer

  let navigate = useNavigate()

  const chooseStore = () => {
    navigate(URLS.AUTH_STORE.replace(':id', id))
  }

  return (
    <div className={cn(s[color], s.drawerDetails)}>
      <span className={s.index}>{index}.</span>
      <div className={s.dcTitle} onClick={chooseStore}>
        <div
          className={s.chartTitleBar}
          style={{ transform: `translate3d(${fillPercentage}%,0,0)` }}
        ></div>
        <h5>
          {county && <em>{county} /</em>}
          {storeName}
        </h5>
        <strong>
          {fillPercentage}
          <em>%</em>
        </strong>
      </div>
    </div>
  )
}

export default Drawer
