import moment from 'moment'

export const getReportField = fieldName => fieldName.toLowerCase().replace(' ', '_')

export const formatValue = (value, type) =>
  type === 'percent'
    ? `${Math.round(value * 100)}%`
    : type === 'datetime'
    ? formatReportDate(value)
    : value

export const formatReportDate = date => moment(date).format('MMMM Do YYYY, h:mm')
