import { useMsal } from '@azure/msal-react'
import cn from 'classnames'
import s from './MainMenu.scss'

const MainMenu = ({ visible }) => {
  const { instance } = useMsal()

  const handleLogout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: '/'
    })
  }

  return (
    <section className={cn(s.mainMenu, { [s.visible]: visible })}>
      <nav>
        <a onClick={handleLogout}>
          <i className="icon-pin-outline"></i>
          <span>Logout</span>
          <strong>Logout</strong>
        </a>
        {/*<a href="#">*/}
        {/*  <i className="icon-pin-outline"></i>*/}
        {/*  <span onClick={handleLogout}>Locate</span>*/}
        {/*  <strong>Stores</strong>*/}
        {/*</a>*/}
        {/*<a href="#">*/}
        {/*  <i className="icon-pin-outline"></i>*/}
        {/*  <span>Store, User</span>*/}
        {/*  <strong>Management</strong>*/}
        {/*</a>*/}
        {/*<a href="#">*/}
        {/*  <i className="icon-pin-outline"></i>*/}
        {/*  <span>App, Profile</span>*/}
        {/*  <strong>Settings</strong>*/}
        {/*</a>*/}
        {/*<a href="#">*/}
        {/*  <i className="icon-pin-outline"></i>*/}
        {/*  <span>Locate</span>*/}
        {/*  <strong>Support</strong>*/}
        {/*</a>*/}
      </nav>
    </section>
  )
}

export default MainMenu
