import cn from 'classnames'
import { HeaderIcon } from '../HeaderIcon'
import s from './Overlay.scss'

const Overlay = ({ isVisible, setVisible, title, storeName, children }) => {
  return (
    <section className={cn(s.overlay, { [s.visible]: isVisible })}>
      <div className={s.storeOverlayHeader}>
        <div className={s.sogHeader}>
          <span className={cn(s.backBtn, s.closeBox)} onClick={() => setVisible(false)} />
          <h6>{title}</h6>
        </div>
        <div className={s.sogGroup}>
          <HeaderIcon hasCircles className={s.overlaySvg} />
          <h3>{storeName}</h3>
        </div>
      </div>
      <div className={s.boxBody}>{children}</div>
    </section>
  )
}

export default Overlay
