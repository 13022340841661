import { Map } from './Map'
import { ContactDetails } from './ContactDetails'
import s from './Contact.scss'

const Contact = ({ store }) => {
  return (
    <div className={s.boxBody}>
      <div className={s.mapsWrapper}>
        {store.lat && store.lng && <Map lat={store.lat} lng={store.lng} />}
      </div>
      <ContactDetails store={store} />
    </div>
  )
}

export default Contact
