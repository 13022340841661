import { useContext } from 'react'
import s from './Header.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Thumbs } from 'swiper'
import { SwiperContext } from '../LayoutContainer'
import { HeaderMenu } from 'components/common/HeaderMenu'
import cn from 'classnames'

const HeaderContainer = ({ className, toggleMenu, menuVisible }) => {
  const swiperContext = useContext(SwiperContext)

  return (
    <div
      className={cn(s.headerWrapper, {
        [className]: className
      })}
    >
      <HeaderMenu toggleMenu={toggleMenu} menuVisible={menuVisible} />
      <nav className={cn(s.tabsNav, 'tabs-nav', { [s.menuVisible]: menuVisible })}>
        <Swiper
          modules={[Thumbs]}
          watchSlidesProgress
          onSwiper={swiperContext.setThumbsSwiper}
          className={s.swiperWrapper}
          slidesPerView={3}
          spaceBetween={0}
        >
          <SwiperSlide className={cn(s.swiperSlide, 'swiper-slide')}>Analytics</SwiperSlide>
          <SwiperSlide className={cn(s.swiperSlide, 'swiper-slide')}>Reports</SwiperSlide>
        </Swiper>
      </nav>
    </div>
  )
}

export default HeaderContainer
