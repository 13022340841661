import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper'
import cn from 'classnames'
import { Indicator } from './Indicator'
import s from './Indicators.scss'

const Indicators = ({ indicators, selectReportType, reportTypes, openReport }) => {
  return (
    <section className={cn(s.cardsSlider, 'cards-slider')}>
      <Swiper
        className={s.swiperWrapper}
        modules={[Navigation, Pagination]}
        speed={700}
        slidesPerView={2.66}
        spaceBetween={10}
        pagination={{
          clickable: true
        }}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }}
      >
        {indicators.map((indicator, index) => (
          <SwiperSlide className={s.swiperSlide} key={`${indicator.id}-${index}`}>
            <Indicator
              indicator={indicator}
              selectReportType={selectReportType}
              reportTypes={reportTypes}
              openReport={openReport}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  )
}

export default Indicators
