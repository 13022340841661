import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { UPDATE_PAGE_TITLE } from 'actions/actionTypes'

const usePageTitle = ({ retailer, store }) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch({
      type: UPDATE_PAGE_TITLE,
      payload: {
        retailer,
        store
      }
    })
  }, [dispatch, retailer, store])
}

export default usePageTitle
