import { applyMiddleware, compose, createStore, combineReducers } from 'redux'
import { createReduxHistoryContext } from 'redux-first-history'
import createSagaMiddleware from 'redux-saga'

import { createBrowserHistory } from 'history'

import reducers from './rootReducer'

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory()
})

export const sagaMiddleware = createSagaMiddleware()

const loadingLockMiddleware = () => next => action => {
  if (action.loadingLock === 'on') {
    document.body.style.cursor = 'wait'
    document.getElementById('app').style.pointerEvents = 'none'
  } else if (action.loadingLock === 'off') {
    document.body.style.cursor = 'default'
    document.getElementById('app').style.pointerEvents = 'auto'
  }

  next(action)
}

const middleware = [loadingLockMiddleware, routerMiddleware, sagaMiddleware]

if (process.env.ENV !== 'production') {
  const logger = require('redux-logger').default
  middleware.push(logger)
}

function configureStore(initialState = {}) {
  const store = createStore(
    combineReducers({
      ...reducers,
      router: routerReducer
    }),
    initialState,
    compose(applyMiddleware(...middleware))
  )

  return store
}

export const store = configureStore()

export const history = createReduxHistory(store)
