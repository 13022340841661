import { useState } from 'react'
import { Link } from 'react-router-dom'
import { URLS } from 'utils/constants'
import { HeaderIcon } from './HeaderIcon'
import { Overlay } from './Overlay'
import { Contact } from './Contact'
import { HeaderMenu } from 'components/common/HeaderMenu'
import s from './StoreHeader.scss'
import cn from 'classnames'

const StoreHeader = ({ store, menuState }) => {
  const [showMap, setShowMap] = useState(false)
  const [menuVisible, setMenuVisible] = menuState

  const toggleMenu = () => setMenuVisible(!menuVisible)

  return (
    <>
      <header className={cn(s.header, { [s.viewMenu]: menuVisible }, { [s.viewOverlay]: showMap })}>
        <Link className={s.logo} to={URLS.AUTH_DASHBOARD} />
        <HeaderMenu toggleMenu={toggleMenu} menuVisible={menuVisible} />
      </header>
      <div className={cn(s.storeHeader, { [s.viewMenu]: menuVisible })}>
        <HeaderIcon hasStoreIcon />
        <div className={s.storeHeaderDetails}>
          <span>{store.schedule}</span>
          <h1>{store.name}</h1>
          <strong>{store.address}</strong>
        </div>
        <div className={s.storeHeaderMenu}>
          <i className="icon-map-pin" onClick={() => setShowMap(true)}></i>
          {window.mobileCheck() && (
            <a href={`tel:${store.phone}`}>
              <i className="icon-phone" />
            </a>
          )}
        </div>
      </div>
      <Overlay isVisible={showMap} setVisible={setShowMap} title="Contact" storeName={store.name}>
        <Contact store={store} />
      </Overlay>
    </>
  )
}

export default StoreHeader
