import { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Thumbs } from 'swiper'
import { SwiperContext } from 'components/Layout/LayoutContainer'
import { Analytics } from './Analytics'
import { Reports } from './Reports'
import { PageTitle } from 'components/common/PageTitle'
import { makeGetRequest, makePostRequest } from 'utils/api'
import { getRetailer } from 'utils/helpers'
import { toReportTypes, toRequestFilters } from './serializers'
import { toChartData, toDrawersData, toKPIIndicators } from './Analytics/serializers'
import { getFiltersForRequests } from './helpers'
import { Filters } from 'components/common/Filters'
import cn from 'classnames'
import s from './Dashboard.scss'

const initialReport = {
  name: '',
  icon: '',
  type: ''
}

const Dashboard = () => {
  const [requestFilters, setRequestFilters] = useState(null)
  const [selectedReportType, setSelectedReportType] = useState(initialReport)
  const [reportTypes, setReportTypes] = useState([])
  const [isReportVisible, setIsReportVisible] = useState(false)
  const [department, setDepartment] = useState(null)
  const [pieChartData, setPieChartData] = useState([])
  const [barChartData, setBarChartData] = useState([])
  const [drawers, setDrawers] = useState([])
  const [indicators, setIndicators] = useState([])
  const [showError, setShowError] = useState(false)
  const [showFilters, setShowFilters] = useState(false)

  const swiperContext = useContext(SwiperContext)

  const filters = useSelector(state => state.filters)

  useEffect(() => {
    ;(async () => {
      const response = await makeGetRequest('reporting/v1.0.0-alpha/reports/getReportsList')
      setReportTypes(toReportTypes(response))
    })()
  }, [])

  useEffect(() => {
    const { timeFilter, locationFilter } = filters
    setRequestFilters(getFiltersForRequests(timeFilter, locationFilter))
  }, [filters])

  useEffect(() => {
    ;(async () => {
      try {
        if (department && department.departmentUid && requestFilters) {
          const { dateIntervals, regionUids, locationUids } = requestFilters
          const compartmentStatesResponse = await makePostRequest(
            'reporting/v1.0.0-alpha/stats/getCompartmentStates',
            toRequestFilters({
              department,
              dateIntervals: [dateIntervals[1]],
              regionUids,
              locationUids
            })
          )
          if (!compartmentStatesResponse.length || !compartmentStatesResponse[0]) {
            setShowError(true)
          } else {
            setShowError(false)
            const { pieChart, barChart } = toChartData(
              compartmentStatesResponse[0].compartmentStatistics
            )
            setPieChartData(pieChart)
            setBarChartData(barChart)
            setDrawers(toDrawersData(compartmentStatesResponse[0].topLocations))
          }

          const kpiResponse = await makePostRequest(
            'reporting/v1.0.0-alpha/stats/getKPIs',
            toRequestFilters({
              department,
              dateIntervals,
              regionUids,
              locationUids
            })
          )
          setIndicators(toKPIIndicators(kpiResponse))
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e)
      }
    })()
  }, [department, requestFilters])

  useEffect(() => {
    const retailer = getRetailer()
    if (retailer.departments.length) {
      setDepartment(retailer.departments[0])
    }
  }, [])

  const retailer = getRetailer()

  const selectReportType = report => {
    setSelectedReportType(report)
  }

  const openReport = () => {
    setIsReportVisible(true)
  }

  const closeReport = () => {
    setSelectedReportType(initialReport)
    setIsReportVisible(false)
  }

  return (
    <>
      <PageTitle retailer={retailer?.retailerName} />
      <main className={s.tabsBody}>
        {!swiperContext.thumbsSwiper?.destroyed && requestFilters && indicators.length && (
          <Swiper
            className={s.tabsBodySwiperWrapper}
            modules={[Thumbs]}
            thumbs={{ swiper: swiperContext.thumbsSwiper }}
            spaceBetween={0}
            autoHeight={true}
            allowTouchMove={false}
            effect={'fade'}
            fadeEffect={{ crossFade: true }}
          >
            <SwiperSlide className={cn(s.tabBody, 'analytics-slide')}>
              <div className={cn(s.sectionContent, 'section-content')}>
                <Analytics
                  filters={filters}
                  requestFilters={requestFilters}
                  isReportVisible={isReportVisible}
                  openReport={openReport}
                  closeReport={closeReport}
                  selectedReportType={selectedReportType}
                  selectReportType={selectReportType}
                  reportTypes={reportTypes}
                  pieChartData={pieChartData}
                  barChartData={barChartData}
                  drawers={drawers}
                  indicators={indicators}
                  showError={showError}
                  showFilters={showFilters}
                  setShowFilters={setShowFilters}
                />
              </div>
            </SwiperSlide>
            <SwiperSlide className={s.tabBody}>
              <div className={cn(s.sectionContent, 'section-content')}>
                <Reports
                  filters={filters}
                  requestFilters={requestFilters}
                  reportTypes={reportTypes}
                  selectedReportType={selectedReportType}
                  selectReportType={selectReportType}
                  isReportVisible={isReportVisible}
                  openReport={openReport}
                  closeReport={closeReport}
                  showFilters={showFilters}
                  setShowFilters={setShowFilters}
                />
              </div>
            </SwiperSlide>
          </Swiper>
        )}
      </main>
      <Filters withHeader setShowFilters={setShowFilters} />
    </>
  )
}

export default Dashboard
