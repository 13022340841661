import s from './ContactDetails.scss'

const ContactDetails = ({ store }) => {
  return (
    <div className={s.storeContactDetails}>
      <h6 className={s.bbTitle}>Store Info</h6>
      {store.phone && (
        <p className={s.contactInfoLine}>
          <i className="icon-phone"></i>
          <a href={`tel:${store.phone}`}>{store.phone}</a>
        </p>
      )}
      {store.email && (
        <p className={s.contactInfoLine}>
          <i className="icon-mail"></i>
          <a href={`mailto:${store.email}`}>{store.email}</a>
        </p>
      )}
      {store.workingHoursToday && (
        <p className={s.contactInfoLine}>
          <i className="icon-clock" />
          <span>
            {/* <strong className={s.green}>Open</strong>, Closes 10 PM */}
            <strong className={s.green}>Opens {store.workingHoursToday.timeOpen} </strong>, Closes{' '}
            {store.workingHoursToday.timeClose}
          </span>
        </p>
      )}
      {store.address && (
        <p className={s.contactInfoLine}>
          <i className="icon-map-pin" />
          <span>{store.address}</span>
        </p>
      )}
    </div>
  )
}

export default ContactDetails
