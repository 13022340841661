import { useState, useEffect } from 'react'
import { getLocationHeader, getTimeFilterName } from 'components/common/Filters/helpers'
import { ReportTable } from './ReportTable'
import { ReportActions } from './ReportActions'
import { makePostRequest } from 'utils/api'
import { getRetailer } from 'utils/helpers'
import { toReport } from './serializers'
import Modal from '@mui/material/Modal'
import { toRequestFilters } from '../../serializers'
import { formatReportDate } from './helpers'
import cn from 'classnames'
import s from './Report.scss'

const style = {
  headerStyle: {
    color: '#082E6F',
    fontSize: '1.28rem',
    textTransform: 'uppercase',
    paddingTop: '1rem',
    paddingBottom: '1.2rem',
    borderBottom: '2px solid #E8EEF9'
  },
  cellStyle: {
    color: '#082E6F',
    fontSize: '1.48rem',
    lineHeight: '1.8rem'
  }
}

const Report = ({
  isVisible,
  closeReport,
  selectedReport,
  filters,
  requestFilters,
  showFilters,
  setShowFilters
}) => {
  const [department, setDepartment] = useState(null)

  const [report, setReport] = useState(null)

  useEffect(() => {
    const retailer = getRetailer()
    if (retailer.departments.length) {
      setDepartment(retailer.departments[0])
    }
  }, [])

  useEffect(() => {
    ;(async () => {
      if (isVisible && department) {
        const { dateIntervals, regionUids, locationUids } = requestFilters
        const { start, end } = dateIntervals[1]
        const response = await makePostRequest(
          `reporting/v1.0.0-alpha/reports/previewReport/${selectedReport.id}`,
          toRequestFilters({
            department: department,
            start,
            end,
            regionUids,
            locationUids
          })
        )
        setReport(toReport(response))
      }
    })()
  }, [department, isVisible, requestFilters, selectedReport.id])

  const closeReportModal = () => {
    setReport(null)
    closeReport()
  }

  const hideReportModal = () => setShowFilters(!showFilters)

  return (
    <Modal
      open={isVisible}
      onClose={closeReportModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={cn({ [s.modalHide]: showFilters })}
    >
      <section
        className={cn(
          s.reportPreview,
          { [s.viewPreviewReport]: isVisible },
          { [s.fillingDegree]: selectedReport.type === 'fillingDegree' }
        )}
      >
        <div className={s.boxHeader}>
          <h3>{selectedReport.name} Report</h3>
        </div>
        <div className={cn(s.squareButton, s.closeBox)} onClick={closeReportModal}>
          <i className="icon-close" />
        </div>
        <div className={cn(s.boxBody, s.reportPreviewBody)}>
          <div className={s.reportPreviewDetails}>
            <p>
              <i className="icon-pin-bold" />
              {getLocationHeader(filters)}
            </p>
            <p>
              <i className="icon-calendar-bold" />
              {getTimeFilterName(filters.timeFilter)}
            </p>
            <p>
              <i className="icon-group"></i>
              {department?.departmentName}
            </p>
            <p>
              <i className="icon-time-bold"></i>
              {!!report?.dateGenerated && formatReportDate(report.dateGenerated)}
            </p>
          </div>
          <div className={s.reportPreviewTable}>
            {report && <ReportTable style={style} report={report} />}
          </div>
        </div>
        <ReportActions
          selectedReportId={selectedReport.id}
          requestFilters={requestFilters}
          departmentUid={department?.departmentUid}
          hideReportModal={hideReportModal}
        />
      </section>
    </Modal>
  )
}

export default Report
