import cn from 'classnames'
import s from './Button.scss'

const Button = ({ text, onClickCb, className, iconClassName, withIcon, transparent }) => {
  return (
    <button
      className={cn(s.button, className, { [s.transparent]: transparent })}
      type="submit"
      onClick={onClickCb}
    >
      {withIcon && <i className={iconClassName}></i>} {text}
    </button>
  )
}

export default Button
