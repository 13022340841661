import { useState, useEffect, useCallback } from 'react'
import { useImmer } from 'use-immer'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper'
import { ShelfPieChart } from './ShelfPieChart'
import { DrawersGrapth } from './DrawersGrapth'
import { DrawersDetailsModal } from './DrawersDetailsModal'
import { makeGetRequest } from 'utils/api'
import { toShelfData, toShelvesData, toDrawersData } from './serializers'
import { toDepartmentSelect } from '../serializers'
import { getDatesForInterval } from './helpers'
import { Button } from 'components/rbc-core-ui'
import { Filters } from './Filters'
import cn from 'classnames'
import s from './Shelves.scss'

const intervals = [
  {
    value: 'live',
    label: 'Current status'
  },
  {
    value: 'hour',
    label: '1 hour'
  },
  {
    value: 'week',
    label: '1 week'
  }
]

const Shelves = ({ storeId, retailer, menuVisible }) => {
  const [department, setDepartment] = useState(toDepartmentSelect(retailer.departments[0]))
  const [interval, setInterval] = useState(intervals[0])
  const [drawersData, setDrawersData] = useImmer([])
  const [shelvesData, setShelvesData] = useState([])
  const [activeShelf, setActiveShelf] = useState(null)
  const [isDrwersModalVisible, setIsDrawersModalVisible] = useState(false)
  const [initialSlide, setInitialSlide] = useState(0)

  useEffect(() => {
    getShelvesData()
  }, [getShelvesData, storeId, interval])

  useEffect(() => {
    if (activeShelf) {
      setDrawersData(toDrawersData(activeShelf.compartments))
    }
  }, [activeShelf, setDrawersData])

  const getShelvesData = useCallback(async () => {
    try {
      const { startDate, endDate } = getDatesForInterval(interval)
      let url = `reporting/v1.0.0-alpha/locations/getCompartmentsFillDegree/${storeId}/${department.value}`
      if (startDate && endDate) {
        url = `${url}?dateStart=${startDate}&dateEnd=${endDate}`
      }
      const result = await makeGetRequest(url)
      setShelvesData(toShelvesData(result))
      setActiveShelf(toShelfData(result[0]))
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
    }
  }, [department.value, interval, storeId])

  const onDrawerClick = node => {
    const selectedDrawer = node.data
    const selectedDrawerInex = drawersData.findIndex(drawer => drawer.id === selectedDrawer.id)
    setInitialSlide(selectedDrawerInex)
    setIsDrawersModalVisible(true)
  }

  const closeDrawersDetails = () => {
    setIsDrawersModalVisible(false)
  }

  return (
    <div className={s.shelvesContainer}>
      <Filters
        departments={retailer.departments}
        department={department}
        setDepartment={setDepartment}
        intervals={intervals}
        interval={interval}
        setInterval={setInterval}
        menuVisible={menuVisible}
      />
      <div className={s.shelvesTabNavContent}>
        <div className={s.wrapperContent}>
          <p className={s.refreshBtnBox}>
            <Button
              text="Refresh data"
              onClickCb={getShelvesData}
              withIcon
              iconClassName="icon-refresh-cw"
              transparent
              className={s.refreshBtn}
            />
          </p>
          <div className={cn(s.shelvesTabNav, 'shelves-tab-nav')}>
            <Swiper
              className={s.swiperWrapper}
              modules={[Navigation, Pagination]}
              speed={700}
              slidesPerView={3}
              spaceBetween={10}
              preventClicks={false}
              preventClicksPropagation={false}
              pagination={{
                clickable: true
              }}
              navigation={{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
              }}
              breakpoints={{
                // when window width is >= 320px
                320: {
                  slidesPerView: '2'
                },
                // when window width is >= 720px
                740: {
                  slidesPerView: '3'
                },
                // when window width is >= 960px
                960: {
                  slidesPerView: '3'
                }
              }}
            >
              {shelvesData.map(shelfData => (
                <SwiperSlide className={s.swiperSlide} key={shelfData.id}>
                  <div className={s.shelfPieChart} onClick={() => setActiveShelf(shelfData)}>
                    <ShelfPieChart
                      data={shelfData.pieChartData}
                      shelfName={shelfData.name}
                      isActive={shelfData.id === activeShelf.id}
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
      <div className={s.shelvesTabContent}>
        <div className={s.wrapperContent}>
          <DrawersGrapth drawersData={drawersData} onDrawerClick={onDrawerClick} />
        </div>
      </div>
      <div className={s.sectionContent}>
        <div className={s.wrapperContent}>
          <div className={s.infoBox}>
            <span className={cn(s.indicatorArrow, s.up)} />
            <p className={s.infoText}>Please click/tap any ”drawer” to preview its live content!</p>
          </div>
        </div>
      </div>
      <DrawersDetailsModal
        isVisible={isDrwersModalVisible}
        closeDrawersDetails={closeDrawersDetails}
        drawers={drawersData}
        initialSlide={initialSlide}
        setDrawersData={setDrawersData}
      />
    </div>
  )
}

export default Shelves
