import { getReportField, formatValue } from './helpers'

export const toReport = report => {
  const { columnDescriptors, reportData } = report

  const columns = columnDescriptors
    ? columnDescriptors.map(columnDescriptor => {
        return {
          title: columnDescriptor.name,
          field: getReportField(columnDescriptor.name),
          width: columnDescriptor.valueType === 'integer' ? '5%' : 'auto'
        }
      })
    : []

  const data = reportData.map(row => {
    const result = {}
    row.map((column, index) => {
      const key = getReportField(columnDescriptors[index].name)
      result[key] = formatValue(column, columnDescriptors[index].valueType)
    })
    return result
  })

  return {
    columns: columns,
    data: data,
    dateGenerated: Date.now()
  }
}
