import cn from 'classnames'
import { Drawer } from './Drawer'
import s from './Drawers.scss'

const Drawers = ({ drawers, label, color }) => {
  return (
    <>
      <div className={cn(s.boxHeader, s[color])}>
        <h3>{label} drawers</h3>
      </div>
      <div className={s.boxBody}>
        <h6>Locations</h6>
        <ul className={s.drawersChartList}>
          {drawers.map((drawer, index) => (
            <li key={drawer.id}>
              <Drawer index={index + 1} drawer={drawer} color={color} />
            </li>
          ))}
        </ul>
      </div>
    </>
  )
}

export default Drawers
