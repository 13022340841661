import { ResponsiveBar } from '@nivo/bar'
import { ChartTooltip } from 'components/common/ChartTooltip'

const keys = ['percentage']

const BarChart = ({ data, setSelectedStatus, setIsDrawersModalVisible }) => {
  const chartStatusClick = node => {
    const selectedStatus = node.data.id
    setSelectedStatus(selectedStatus)
    setIsDrawersModalVisible(true)
  }

  return (
    <ResponsiveBar
      data={data}
      keys={keys}
      colors={{ datum: 'data.color' }}
      indexBy="status"
      margin={{ top: 50, right: 40, bottom: 50, left: 100 }}
      padding={0.3}
      groupMode="grouped"
      borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
      axisTop={null}
      axisLeft={null}
      axisBottom={{
        tickSize: 0,
        tickPadding: 5,
        tickRotation: 0,
        legendPosition: 'middle',
        legendOffset: 32
      }}
      axisRight={{
        tickSize: 0,
        tickPadding: 5,
        tickRotation: 0,
        legendPosition: 'middle',
        legendOffset: -40,
        tickValues: [0, 25, 50, 75, 100]
      }}
      minValue={0}
      maxValue={100}
      enableLabel={false}
      enableGridY={false}
      onClick={chartStatusClick}
      legendLabel={datum => `${datum.value}% ${datum.data.status}`}
      legends={[
        {
          dataFrom: 'indexes',
          anchor: 'bottom-left',
          direction: 'column',
          justify: false,
          translateX: -80,
          translateY: 0,
          itemsSpacing: 14,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: 'left-to-right',
          itemOpacity: 1,
          symbolSize: 20,
          effects: [
            {
              on: 'hover',
              style: {
                itemOpacity: 1
              }
            }
          ]
        }
      ]}
      animate
      motionStiffness={90}
      motionDamping={15}
      tooltip={({ data }) => (
        <ChartTooltip color={data.color}>
          {data.status}: <strong>{data.percentage}%</strong>
        </ChartTooltip>
      )}
    />
  )
}

export default BarChart
