import { getChartColorByStatus } from 'utils/helpers'
import { getTrendPercentage, getTrend } from './helpers'

export const toChartData = data => {
  const { empty, low, full, offline, invalid } = data

  const pieChart = [
    toPieChart(toChartValue(empty), 'Empty'),
    toPieChart(toChartValue(low), 'Low'),
    toPieChart(toChartValue(full), 'Full'),
    toPieChart(toChartValue(offline), 'Offline'),
    toPieChart(toChartValue(invalid), 'Invalid')
  ]

  const barChart = [
    toBarChart(toChartValue(empty), 'Empty'),
    toBarChart(toChartValue(low), 'Low'),
    toBarChart(toChartValue(full), 'Full'),
    toBarChart(toChartValue(offline), 'Offline'),
    toBarChart(toChartValue(invalid), 'Invalid')
  ]

  return {
    pieChart,
    barChart
  }
}

const toChartValue = value => Math.round(value * 100 * 100) / 100

const toPieChart = (value, label) => ({
  id: label.toLowerCase(),
  label: `${value}% ${label}`,
  status: label,
  value: value,
  color: getChartColorByStatus(label)
})

const toBarChart = (value, label) => ({
  id: label,
  status: label,
  percentage: value,
  color: getChartColorByStatus(label)
})

export const toDrawersData = data => {
  const { empty, low, full, offline, invalid } = data

  const emptyItems = empty.map(item => toDrawer(item, 'empty'))
  const lowItems = low.map(item => toDrawer(item, 'low'))
  const fullItems = full.map(item => toDrawer(item, 'full'))
  const offlineItems = offline.map(item => toDrawer(item, 'offline'))
  const invalidItems = invalid.map(item => toDrawer(item, 'invalid'))

  return [...emptyItems, ...lowItems, ...fullItems, ...offlineItems, ...invalidItems]
}

const toDrawer = (item, status) => {
  const { locationUid, locationName, metric } = item

  return {
    id: locationUid,
    county: '',
    storeName: locationName,
    fillPercentage: Math.round(metric * 100),
    status
  }
}

export const toKPIIndicators = kpiIndicators =>
  kpiIndicators.map(indicator => {
    const { kpiUid, kpiName, largerBetter, values } = indicator
    const currentValue = values[1]
    const previousValue = values[0]
    const trendPercentage = getTrendPercentage(currentValue.kpiValue, previousValue.kpiValue)

    return {
      id: kpiUid,
      name: kpiName,
      trend: getTrend(trendPercentage, largerBetter),
      trendPercentage,
      totalPercentage: currentValue.kpiDisplay,
      averagePercentage: previousValue.kpiDisplay
    }
  })
