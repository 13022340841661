import { useState, useEffect, useCallback } from 'react'
import Modal from '@mui/material/Modal'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper'
import ReactLoading from 'react-loading'
import moment from 'moment'
import { makeGetRequest } from 'utils/api'
import cn from 'classnames'
import s from './DrawersDetailsModal.scss'

const DrawersDetailsModal = ({
  isVisible,
  closeDrawersDetails,
  drawers,
  initialSlide,
  setDrawersData
}) => {
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (isVisible) {
      getCurrentImage(initialSlide)
    }
  }, [getCurrentImage, initialSlide, isVisible])

  const getCurrentImage = useCallback(
    async index => {
      if (!drawers[index].image && drawers[index].imageUid) {
        setIsLoading(true)
        try {
          const result = await makeGetRequest(
            `reporting/v1.0.0-alpha/files/getCompartmentImage/${drawers[index].imageUid}/`,
            { responseType: 'blob' }
          )
          setDrawersData(state => {
            state[index].image = URL.createObjectURL(result)
          })
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log(e)
        }
        setIsLoading(false)
      }
    },
    [drawers, setDrawersData]
  )

  return (
    <Modal
      open={isVisible}
      onClose={closeDrawersDetails}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <section className={cn(s.drawersDetailsOverlay, 'ddo-slider')}>
        <div className={s.closeBox} onClick={closeDrawersDetails}>
          <i className="icon-close" />
        </div>
        <Swiper
          className={s.ddoSlider}
          modules={[Navigation, Pagination]}
          speed={700}
          slidesPerView={1}
          spaceBetween={24}
          pagination={{
            clickable: true,
            el: '.swiper-pagination-fraction',
            type: 'custom',
            renderCustom: swiper => {
              const index = swiper.activeIndex
              return drawers[index].productCount
            }
          }}
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          }}
          initialSlide={initialSlide}
          onSlideChange={swiper => getCurrentImage(swiper.activeIndex)}
        >
          {drawers.map(drawer => (
            <SwiperSlide className={s.swiperSlide} key={drawer.id}>
              <div className={cn(s.shelfPieChart, { [s.noImage]: !drawer.imageUid })}>
                <div className={s.ddoHeader}>
                  <h3>{drawer.name} Drawer</h3>
                  {drawer.date && (
                    <p>Last checked: {moment(drawer.date).format('Do MMM YYYY, h:mm')}</p>
                  )}
                </div>
                {isLoading ? (
                  <div className={cn(s.ddoBody, s.loading)}>
                    <ReactLoading type="spinningBubbles" />
                  </div>
                ) : drawer.imageUid ? (
                  <div className={s.ddoBody} style={{ backgroundImage: `url(${drawer.image})` }} />
                ) : (
                  <div className={s.ddoBody}>No image available</div>
                )}
                <div className={s.bottomInfo}>
                  <div className={s.progressBarContainer}>
                    <div className={s.progressBar}>
                      <div
                        className={s.activeProgress}
                        style={{ width: `${drawer.isEmpty ? 0 : drawer.percentage}%` }}
                      />
                      <span
                        className={s.activeIndicator}
                        style={{
                          left: `calc(${drawer.isEmpty ? 0 : drawer.percentage}% - 0.6rem)`
                        }}
                      >
                        <i className={cn('icon-arrow-up-3')} />
                        {drawer.isEmpty ? 0 : drawer.percentage}%
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className={cn('swiper-pagination-fraction', s.swiperPaginationFraction)} />
        <div className={cn('swiper-button-prev', s.swiperBtn)}></div>
        <div className={cn('swiper-button-next', s.swiperBtn)}></div>
      </section>
    </Modal>
  )
}

export default DrawersDetailsModal
