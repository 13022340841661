import 'regenerator-runtime/runtime'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { HistoryRouter as Router } from 'redux-first-history/rr6'

import { store, sagaMiddleware, history } from './configureStore'
import rootSaga from './rootSaga'

import App from 'components/App/App.js'

import '!style-loader!css-loader!react-day-picker/lib/style.css' // eslint-disable-line
import '!style-loader!css-loader!swiper/css/bundle' // eslint-disable-line
import '!style-loader!css-loader!swiper/css/navigation' // eslint-disable-line
import '!style-loader!css-loader!swiper/css/pagination' // eslint-disable-line

import '!style-loader!css-loader!styles/overrides/react-day-picker.css' // eslint-disable-line
import '!style-loader!css-loader!styles/overrides/react-select.css' // eslint-disable-line
import '!style-loader!css-loader!styles/overrides/react-month-picker.css' // eslint-disable-line
import '!style-loader!css-loader!styles/overrides/css-resetter.css' // eslint-disable-line
import '!style-loader!css-loader!styles/overrides/swiper.css' // eslint-disable-line
import '!style-loader!css-loader!styles/overrides/icons.css' // eslint-disable-line

sagaMiddleware.run(rootSaga)

const rootEl = document.getElementById('app')
const root = createRoot(rootEl)

const renderApp = Component => {
  root.render(
    <Provider store={store}>
      <Router history={history}>
        <Component />
      </Router>
    </Provider>
  )
}

renderApp(App)
