import { useState, useEffect } from 'react'
import cn from 'classnames'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import '!style-loader!css-loader!react-datepicker/dist/react-datepicker.css' // eslint-disable-line
import s from './TimeFilters.scss'

const TimeFilters = ({
  isVisible,
  selectedFilters,
  setFilterValue,
  customIntervalVisible,
  setCustomIntervalVisible
}) => {
  const [selectedTimeInterval, setSelectedTimeInterval] = useState('today')

  useEffect(() => {
    if (selectedFilters) {
      setSelectedTimeInterval(selectedFilters.selectedTimeFilterOption)
    }
  }, [selectedFilters])

  const chooseTimeFilterOption = (value, disableCustomInterval) => {
    if (disableCustomInterval) {
      setCustomIntervalVisible(false)
    } else {
      setCustomIntervalVisible(!customIntervalVisible)
    }

    setFilterValue('selectedTimeFilterOption', value)
  }

  const syncEndDate = () => {
    if (moment(selectedFilters.startDate).isAfter(moment(selectedFilters.endDate))) {
      const date = moment(selectedFilters.startDate)
        .add(1, 'days')
        .toDate()
      setFilterValue('endDate', date)
    }
  }

  return (
    <div className={cn(s.filterListContent, { [s.viewFilterList]: isVisible })}>
      <div className={s.wrapperContent}>
        <div className={cn(s.formRow, s.cols2)}>
          <div className={s.formCellRadio} onClick={() => chooseTimeFilterOption('today', true)}>
            <input
              type="radio"
              name="timeInt"
              value="today"
              checked={selectedTimeInterval === 'today'}
            />
            <label className={cn({ [s.checked]: selectedTimeInterval === 'today' })}>Today</label>
          </div>
          <div
            className={s.formCellRadio}
            onClick={() => chooseTimeFilterOption('yesterday', true)}
          >
            <input
              type="radio"
              name="timeInt"
              value="yesterday"
              checked={selectedTimeInterval === 'yesterday'}
            />
            <label className={cn({ [s.checked]: selectedTimeInterval === 'yesterday' })}>
              Yesterday
            </label>
          </div>
        </div>
        <div className={cn(s.formRow, s.cols2)}>
          <div className={s.formCellRadio} onClick={() => chooseTimeFilterOption('lastWeek', true)}>
            <input
              type="radio"
              name="timeInt"
              value="lastWeek"
              checked={selectedTimeInterval === 'lastWeek'}
            />
            <label className={cn({ [s.checked]: selectedTimeInterval === 'lastWeek' })}>
              Last 7 days
            </label>
          </div>
          <div
            className={s.formCellRadio}
            onClick={() => chooseTimeFilterOption('lastMonth', true)}
          >
            <input
              type="radio"
              name="timeInt"
              value="lastMonth"
              checked={selectedTimeInterval === 'lastMonth'}
            />
            <label className={cn({ [s.checked]: selectedTimeInterval === 'lastMonth' })}>
              Last 30 days
            </label>
          </div>
        </div>
        <div className={s.formRow}>
          <div className={s.formCellRadio} onClick={() => chooseTimeFilterOption('custom', false)}>
            <input
              id="custom"
              type="radio"
              name="timeInt"
              value="custom"
              className={s.cutomIntervalInput}
              checked={selectedTimeInterval === 'custom'}
            />
            <label className={cn({ [s.checked]: selectedTimeInterval === 'custom' })}>
              Custom interval
            </label>
          </div>
          <div
            className={cn(s.customIntervalContent, {
              [s.viewCustomIntervalContent]: customIntervalVisible
            })}
          >
            <div className={cn(s.formRow, s.cols2)}>
              <div className={cn(s.formCell1, s.formCellInput1)}>
                <label className={s.label}>From</label>
                <DatePicker
                  selected={selectedFilters?.startDate}
                  selectsStart
                  startDate={selectedFilters?.startDate}
                  endDate={selectedFilters?.endDate}
                  onChange={value => {
                    if (!value) return
                    setFilterValue('startDate', value)
                    syncEndDate()
                  }}
                  placeholderText="Select start date"
                  className={s.calendar}
                />
              </div>
              <div className={cn(s.formCell1, s.formCellInput1)}>
                <label className={s.label}>To</label>
                <DatePicker
                  selected={
                    selectedFilters?.endDate ||
                    (selectedFilters?.startDate &&
                      moment(selectedFilters?.startDate)
                        .add(1, 'days')
                        .toDate())
                  }
                  selectsEnd
                  startDate={selectedFilters?.startDate}
                  endDate={selectedFilters?.endDate}
                  onChange={value => {
                    setFilterValue('endDate', value)
                  }}
                  minDate={selectedFilters?.startDate}
                  placeholderText="Select end date"
                  className={s.calendar}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TimeFilters
