import moment from 'moment'
import { getChartColorByStatus, capitalize } from 'utils/helpers'

export const getShelfPercentage = shelf => {
  const reduced = shelf.compartments.reduce(function(acc, compartment) {
    if (!acc[compartment.compartmentStatus]) {
      acc[compartment.compartmentStatus] = {
        status: compartment.compartmentStatus,
        count: 1
      }
      return acc
    }
    acc[compartment.compartmentStatus].count += 1
    return acc
  }, {})

  return Object.keys(reduced).map(key => ({
    value: Math.round((reduced[key].count / shelf.compartments.length) * 100),
    color: getChartColorByStatus(reduced[key].status),
    status: reduced[key].status,
    id: capitalize(reduced[key].status)
  }))
}

export const getGraphColor = percentage => {
  if (percentage === 0) {
    return '#F2F8FF'
  } else if (percentage < 30) {
    return '#FD820B'
  } else {
    return '#00B342'
  }
}

export const getDatesForInterval = interval => {
  switch (interval.value) {
    case 'hour':
      return {
        startDate: moment(Date.now())
          .subtract(1, 'hours')
          .format('YYYY-MM-DDTHH:mm:ss[Z]'),
        endDate: moment(Date.now()).format('YYYY-MM-DDTHH:mm:ss[Z]')
      }
    case 'week':
      return {
        startDate: moment(Date.now())
          .subtract(1, 'weeks')
          .format('YYYY-MM-DDTHH:mm:ss[Z]'),
        endDate: moment(Date.now()).format('YYYY-MM-DDTHH:mm:ss[Z]')
      }
    default:
      return { startDate: '', endDate: '' }
  }
}
