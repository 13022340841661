import { usePageTitle } from 'hooks'

const PageTitle = ({ retailer, store }) => {
  usePageTitle({
    retailer,
    store
  })

  return null
}

export default PageTitle
