export const getDrawersColor = status => {
  switch (status) {
    case 'empty':
      return 'red'
    case 'low':
      return 'orange'
    case 'full':
      return 'green'
    case 'offline':
      return 'grey'
    case 'invalid':
      return 'lighterGrey'
    default:
      return 'red'
  }
}
