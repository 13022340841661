import { Navigate } from 'react-router-dom'
import { useIsAuthenticated } from '@azure/msal-react'
// import { isAuthenticated } from 'utils/authService'
import { NotFound } from 'components/rbc-core-ui'
import { URLS } from 'utils/constants'

const PrivateRoute = ({ component: RouteComponent, ...props }) => {
  // const roles = allow && allow.split(' ')
  // const userRoles = getUserRoles()
  // if no role restriction is present on the route then everything is allowed
  // const isAllowed = roles && userRoles ? userRoles.some(r => roles.includes(r)) : true

  const isAllowed = true

  const isAuthenticated = useIsAuthenticated()

  return isAuthenticated ? (
    isAllowed ? (
      <RouteComponent {...props} />
    ) : (
      <NotFound />
    )
  ) : (
    <Navigate to={URLS.LOGIN} />
  )
}

export default PrivateRoute
