import Select from 'react-select'
import cn from 'classnames'
import s from './Filters.scss'

const customStyles = {
  control: provided => ({
    ...provided,
    border: 'none',
    boxShadow: 'none',
    fontSize: '1.6rem',
    fontFamily: "'Klavika-Regular', Arial, sans-serif"
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  singleValue: provided => ({
    ...provided,
    color: '#082E6F'
  }),
  menu: provided => ({
    ...provided,
    fontSize: '1.6rem',
    fontFamily: "'Klavika-Regular', Arial, sans-serif",
    zIndex: '9999'
  }),
  menuList: provider => ({
    ...provider,
    zIndex: '9999'
  }),
  option: provider => ({
    ...provider,
    zIndex: '9999'
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 })
}

const Filters = ({
  departments,
  department,
  setDepartment,
  intervals,
  interval,
  setInterval,
  menuVisible
}) => {
  return (
    <div className={cn(s.form, s.topFilters, s.cols2, { [s.viewMenu]: menuVisible })}>
      <div className={s.wrapperContent}>
        <div className={cn(s.formCell, s.formCellSelect)}>
          <label className={s.label}>Departament</label>
          <Select
            onChange={value => setDepartment(value)}
            options={departments}
            value={department}
            className={s.select}
            placeholder="Select Department"
            styles={customStyles}
            menuPortalTarget={document.body}
            isDisabled
          />
        </div>
        <div className={cn(s.formCell, s.formCellSelect)}>
          <label className={s.label}>Interval</label>
          <Select
            onChange={value => setInterval(value)}
            options={intervals}
            value={interval}
            className={s.select}
            placeholder="Select Interval"
            styles={customStyles}
            menuPortalTarget={document.body}
          />
        </div>
      </div>
    </div>
  )
}

export default Filters
