/* eslint-disable react/no-children-prop */
import { Routes, Route } from 'react-router-dom'
import { NotFound } from 'components/rbc-core-ui'
import { Home } from '../Home'
import { Dashboard } from '../Dashboard'
import { Store } from '../Store'
import { Login } from '../Login'
import { URLS } from 'utils/constants'
import { PrivateRoute } from 'components/utils/PrivateRoute'
import { LayoutContainer } from 'components/Layout'
import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react'
import { PublicClientApplication } from '@azure/msal-browser'
import { msalConfig } from 'utils/authConfig'

const pca = new PublicClientApplication(msalConfig)

const App = () => (
  <MsalProvider instance={pca}>
    <AuthenticatedTemplate>
      <LayoutContainer>
        <Routes>
          {/* private routes */}
          <Route path={URLS.AUTH_HOME} element={<PrivateRoute component={Home} />} />
          <Route path={URLS.AUTH_DASHBOARD} element={<PrivateRoute component={Dashboard} />} />
          <Route path={URLS.AUTH_STORE} element={<PrivateRoute component={Store} />} />
          {/* end of private routes */}
          <Route path={URLS.LOGIN} element={<Login />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </LayoutContainer>
    </AuthenticatedTemplate>
    <UnauthenticatedTemplate>
      <Login />
    </UnauthenticatedTemplate>
  </MsalProvider>
)

export default App
