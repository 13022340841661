import { useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { makeGetRequest } from 'utils/api'
import { URLS } from 'utils/constants'
import { setRetailer } from 'utils/helpers'
import * as ACT from '../actions'
import { Button } from 'components/rbc-core-ui'
import cn from 'classnames'
import s from './RetailerSelect.scss'

const RetailerSelect = () => {
  const [retailers, setRetailers] = useState([])
  const [selectedRetailer, setSelectedRetailer] = useState(null)

  const reduxDispatch = useDispatch()

  let navigate = useNavigate()

  useEffect(() => {
    ;(async () => {
      try {
        const retailersResponse = await makeGetRequest(
          'reporting/v1.0.0-alpha/retailers/getRetailersList'
        )
        // eslint-disable-next-line no-console
        console.log('getting the retailers')
        if (retailersResponse.length === 1) {
          selectRetailer(retailersResponse[0])
        } else {
          setRetailers(retailersResponse)
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e)
      }
    })()
  }, [selectRetailer])

  useEffect(() => {
    setSelectedRetailer(retailers[0])

    reduxDispatch({
      type: ACT.RESET_FILTERS
    })
  }, [reduxDispatch, retailers])

  useEffect(() => {
    reduxDispatch({
      type: ACT.RESET_FILTERS
    })
  }, [reduxDispatch])

  const selectRetailer = useCallback(
    async defaultRetailer => {
      const retailer = selectedRetailer || defaultRetailer

      reduxDispatch({
        type: ACT.SET_RETAILER,
        payload: {
          retailer
        }
      })

      const response = await makeGetRequest(
        `reporting/v1.0.0-alpha/retailers/getRetailerDetails/${retailer.retailerUid}`
      )
      reduxDispatch({
        type: ACT.SET_DEPARTMENTS,
        payload: { departments: response.departments }
      })

      setRetailer({ ...response, retailerUid: retailer.retailerUid })

      navigate(URLS.AUTH_DASHBOARD)
    },
    [navigate, reduxDispatch, selectedRetailer]
  )

  return (
    <div className={s.bluePageTpl}>
      <section className={cn(s.languageSelectorScreen, s.afterSplashScreen)}>
        {!!retailers.length && (
          <div className={s.sectionContent}>
            <h3>
              <span>Switch to a</span>Specific Retailer
            </h3>
            <h6>What retailer would you like to analyze next?</h6>
            <div className={s.chooseLanguageSlider}>
              <div className={s.swiperWrapper}>
                {retailers.map(retailer => (
                  <div
                    key={retailer.retailerUid}
                    className={cn(s.swiperSlide, {
                      [s.active]: retailer.retailerUid === selectedRetailer?.retailerUid
                    })}
                    onClick={() => setSelectedRetailer(retailer)}
                  >
                    <p>
                      <span>{retailer.retailerName}</span>
                    </p>
                  </div>
                ))}
              </div>
            </div>
            <p className={s.center}>
              <Button
                text="Continue"
                onClickCb={selectRetailer}
                withIcon
                iconClassName="icon-arrow-next-1"
                transparent
              />
            </p>
          </div>
        )}
      </section>
    </div>
  )
}

export default RetailerSelect
