import cn from 'classnames'
// import { IoFunnelOutline } from 'react-icons/io5'
import { getTimeFilterName } from '../helpers'
import s from './FiltersHeader.scss'

const FiltersHeader = ({
  filtersVisible,
  onClick,
  locationHeader,
  selectedFilters,
  departments
}) => {
  return (
    <div
      className={cn(s.filtersHeader, 'filters-header', { [s.viewFilters]: filtersVisible })}
      onClick={onClick}
    >
      <ul>
        <li>
          <span>Period</span>
          <strong>{selectedFilters ? getTimeFilterName(selectedFilters.timeFilter) : ''}</strong>
        </li>
        <li>
          <span>Location</span>
          <strong title={locationHeader}>{locationHeader}</strong>
        </li>
        <li>
          <span>Departments</span>
          <strong>{departments && departments.length ? departments[0]?.departmentName : ''}</strong>
        </li>
      </ul>
      <div className={s.boxHeader}>
        <h3>Widgets filters</h3>
        <p>Would you like to change the default values?</p>
      </div>
      {/* <div className={s.filterIcon}>
        <IoFunnelOutline />
      </div> */}
    </div>
  )
}

export default FiltersHeader
