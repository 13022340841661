export const getInitialSlide = selectedStatus => {
  switch (selectedStatus) {
    case 'empty':
      return 0
    case 'low':
      return 1
    case 'full':
      return 2
    case 'offline':
      return 3
    case 'invalid':
      return 4
    default:
      return 0
  }
}
