import { useState, useEffect } from 'react'
import { SplashScreen } from 'components/common/SplashScreen'
import { PageTitle } from 'components/common/PageTitle'
import { LanguageSelect } from './LanguageSelect'
import { CookieSettings } from './CookieSettings'
import { RetailerSelect } from './RetailerSelect'
import { getLanguageOption } from './LanguageSelect/helpers'
import { getAcceptCookies } from 'utils/helpers'

const Home = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [language, setLanguage] = useState(getLanguageOption() || 'en')
  const [areCookiesAccepted, setAreCookiesAccepted] = useState(getAcceptCookies())

  useEffect(() => {
    setTimeout(() => setIsLoading(false), 10)
  }, [])

  return (
    <>
      <PageTitle />
      {isLoading ? (
        <SplashScreen />
      ) : language ? (
        areCookiesAccepted ? (
          <RetailerSelect />
        ) : (
          <CookieSettings setAreCookiesAccepted={setAreCookiesAccepted} />
        )
      ) : (
        <LanguageSelect setLanguage={setLanguage} />
      )}
    </>
  )
}

export default Home
