import { Component } from 'react'
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps'

const style = {
  width: '100%',
  position: 'relative',
  height: '100%'
}

class Map extends Component {
  state = {
    initialLat: 37.09024,
    initialLng: -95.71289100000001,
    lat: '',
    lng: ''
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      !nextProps.lat ||
      nextProps.lat === nextState.lat ||
      !nextProps.lng ||
      nextProps.lng === nextState.lng
    ) {
      return false
    }
    return true
  }

  componentDidUpdate(prevProps) {
    if (prevProps.lat !== this.state.lat || prevProps.lng !== this.state.lng) {
      const { lat, lng } = prevProps
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        lat,
        lng
      })
    }
  }

  render() {
    const GoogleMapWrapper = withGoogleMap(props => (
      <GoogleMap
        defaultCenter={{
          lat: +props.lat || this.state.initialLat,
          lng: +props.lng || this.state.initialLng
        }}
        defaultZoom={props.lng ? 13 : 3}
      >
        <Marker
          position={{
            lat: +props.lat || this.state.initialLat,
            lng: +props.lng || this.state.initialLng
          }}
        />
      </GoogleMap>
    ))

    return (
      <GoogleMapWrapper
        containerElement={<div style={style} />}
        mapElement={<div style={{ height: '100%' }} />}
        lat={this.props.lat}
        lng={this.props.lng}
      />
    )
  }
}
export default Map
