import { useMsal } from '@azure/msal-react'
import { loginRequest } from 'utils/authConfig'
import { Button } from 'components/rbc-core-ui'
import s from './Login.scss'

const Login = () => {
  const { instance } = useMsal()

  const handleLogin = async () => {
    instance.loginRedirect(loginRequest).catch(e => {
      // eslint-disable-next-line no-console
      console.log(e)
    })
  }

  return (
    <main className={s.wrapper}>
      <section className={s.loginScreen}>
        <div className={s.sectionContent}>
          <div className={s.sectionContentHeader}>
            <h1>
              <span>Welcome to</span>Smart Your Shelf
            </h1>
            <h6>Sign in to continue</h6>
          </div>
          <p className={s.center}>
            <Button
              text="Continue"
              onClickCb={handleLogin}
              iconClassName="icon-arrow-next-1"
              withIcon
            />
          </p>
          <a className={s.backBtn} href="/"></a>
        </div>
      </section>
    </main>
  )
}

export default Login
