import Select from 'react-select'
import cn from 'classnames'
import { toRegionsSelect, toStoresSelect } from './serializers'
import { RegionsFilter } from './RegionsFilter'
import { deepSearchByKeyValue } from 'utils/helpers'
import s from './LocationFilters.scss'

const customStyles = {
  menu: provided => ({
    ...provided,
    fontSize: '1.6rem',
    fontFamily: "'Klavika-Regular', Arial, sans-serif",
    zIndex: '9999'
  })
}

const LocationFilters = ({ isVisible, selectedFilters, setFilterValue, regions, stores }) => {
  return (
    <div className={cn(s.filterListContent, { [s.viewFilterList]: isVisible })}>
      <div className={s.wrapperContent}>
        <div className={cn(s.formCell, s.formCellSelect)}>
          <label className={s.label}>Select region</label>
          <RegionsFilter
            regions={toRegionsSelect(regions)}
            setFilterValue={setFilterValue}
            selectedRegion={selectedFilters?.regions ? selectedFilters?.regions[0] : ''}
            position={0}
            styles={customStyles}
          />
          {regions.length && selectedFilters?.regions ? (
            selectedFilters.regions.map((region, index) => {
              const result = deepSearchByKeyValue(regions, 'regions', region.value)[0]
              if (result?.regions?.length) {
                return (
                  <RegionsFilter
                    key={result.regionUid}
                    regions={toRegionsSelect(result.regions)}
                    setFilterValue={setFilterValue}
                    selectedRegion={selectedFilters.regions[index + 1]}
                    position={index + 1}
                    styles={customStyles}
                  />
                )
              }
            })
          ) : (
            <div />
          )}
        </div>
        <div className={cn(s.formCell, s.formCellSelect)}>
          <label className={s.label}>Select store</label>
          <Select
            onChange={value => setFilterValue('stores', value)}
            options={toStoresSelect(stores)}
            value={selectedFilters?.stores || ''}
            className={cn(s.select, s.multiSelect)}
            placeholder="All Stores"
            isDisabled={!selectedFilters?.regions?.length}
            styles={customStyles}
            isMulti
            isClearable
          />
        </div>
      </div>
    </div>
  )
}

export default LocationFilters
