import cn from 'classnames'
import s from '../Indicators.scss'

const Indicator = ({ indicator, selectReportType, reportTypes, openReport }) => {
  return (
    <section
      className={s.indicatorBody}
      onClick={() => {
        selectReportType(reportTypes.filter(reportType => reportType.id === indicator.id)[0])
        openReport()
      }}
    >
      <span>{indicator.trendPercentage}%</span>
      <strong>{indicator.totalPercentage}</strong>
      <span className={s.averagePercentage}>{indicator.averagePercentage}</span>
      <div>
        <strong>{indicator.name}</strong>
      </div>
      {indicator.trend === 'even' ? (
        <i className={cn('icon-arrow-next-1', s.trendArrow, s.nextArrow)} />
      ) : indicator.trend === 'up' ? (
        <i className={cn('icon-arrow-up-1', s.trendArrow, s.upArrow)} />
      ) : (
        <i className={cn('icon-arrow-down-1', s.trendArrow, s.downArrow)} />
      )}
    </section>
  )
}

export default Indicator
