import { ResponsiveBar } from '@nivo/bar'
import { ChartTooltip } from 'components/common/ChartTooltip'
import { capitalize } from 'utils/helpers'

const keys = ['percentage', 'emptyPercentage']

const DrawersGrapth = ({ drawersData, onDrawerClick }) => {
  return (
    <ResponsiveBar
      data={drawersData}
      keys={keys}
      colors={bar => bar.data[`${bar.id}Color`]}
      indexBy="name"
      margin={{ top: 50, right: 40, bottom: 50, left: 100 }}
      padding={0.3}
      borderColor={bar => bar.data.data.borderColor}
      borderWidth={1}
      borderRadius={0}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 0,
        tickPadding: 5,
        tickRotation: 0,
        legendPosition: 'middle',
        legendOffset: 32
      }}
      axisLeft={{
        tickSize: 0,
        tickPadding: 5,
        tickRotation: 0,
        legendPosition: 'middle',
        legendOffset: -40,
        tickValues: [0, 25, 50, 75, 100]
      }}
      minValue={0}
      maxValue={100}
      enableLabel={false}
      enableGridY={false}
      onClick={onDrawerClick}
      animate
      motionStiffness={90}
      motionDamping={15}
      tooltip={({ data }) =>
        !data.isEmpty ? (
          <ChartTooltip color={data.color}>
            {data.name}:{' '}
            <strong>
              {data.percentage}% ({capitalize(data.status)})
            </strong>
          </ChartTooltip>
        ) : (
          <ChartTooltip>
            <span>
              Click to view image <br />
              for this {data.status} drawer
            </span>
          </ChartTooltip>
        )
      }
    />
  )
}

export default DrawersGrapth
