import { createReducer } from 'redux-create-reducer'
import * as ACT from 'actions/actionTypes'
import update from 'immutability-helper'

const initialState = {
  allRegions: [],
  allStores: [],
  pageTitle: ''
}

const appReducer = createReducer(initialState, {
  [ACT.SET_ALL_REGIONS](state, action) {
    const { regions } = action.payload

    return update(state, {
      allRegions: { $set: regions }
    })
  },

  [ACT.SET_ALL_STORES](state, action) {
    const { stores } = action.payload

    return update(state, {
      allStores: { $set: stores }
    })
  },

  [ACT.UPDATE_PAGE_TITLE](state, action) {
    return {
      ...state,
      pageTitle: {
        ...state.pageTitle,
        ...action.payload
      }
    }
  }
})

export default appReducer
