const downloadFile = (data, filename) => {
  const link = document.createElement('a')
  link.setAttribute('href', data)
  link.setAttribute('download', filename)
  link.click()
}

const getFilename = headers =>
  headers['content-disposition']
    .split('filename=')[1]
    .split('"')
    .join('')

const getContentType = headers => headers['content-type']

export const exportFile = response => {
  const { data, headers } = response
  const filename = getFilename(headers)
  const blob = new Blob([data], {
    type: getContentType(headers)
  })

  const fileData = URL.createObjectURL(blob)
  downloadFile(fileData, filename)
}
