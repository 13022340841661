export const toStore = store => ({
  ...store,
  schedule: `${store.workingHoursToday.timeOpen} - ${store.workingHoursToday.timeClose}`,
  phone: store.phoneNumber,
  lat: store.geo.lat || null,
  lng: store.geo.long || null
})

export const toDepartmentSelect = department => ({
  value: department.departmentUid,
  label: department.departmentName
})

export const toDepartmentsSelect = departments =>
  departments.map(department => toDepartmentSelect(department))
