export const URLS = {
  AUTH_HOME: '/',
  AUTH_DASHBOARD: '/dashboard',
  AUTH_STORE: '/stores/:id',
  LOGIN: '/login'
}

export const URL = {
  development: 'https://rbc-sys.azure-api.net/',
  uat: 'https://rbc-sys.azure-api.net/',
  production: 'https://rbc-sys.azure-api.net/'
}

export const API_KEY = {
  key: 'Ocp-Apim-Subscription-Key',
  value: '4905124bc82a4d2a8c9868b7318eab85'
}

export const MSAL_REDIRECT_URI = {
  development: 'http://localhost:3000/',
  production: 'https://sys.rbc.com.ro/'
}
