import { getChartColorByStatus } from 'utils/helpers'
import { getShelfPercentage } from './helpers'

export const toShelfData = shelf => {
  const { cabinetUid, cabinetName, compartments } = shelf
  const pieChartData = getShelfPercentage(shelf)

  return {
    id: cabinetUid,
    name: cabinetName,
    compartments: compartments,
    pieChartData
  }
}

export const toShelvesData = data => data.map(item => toShelfData(item))

export const toDrawersData = data =>
  data.map(item => {
    const {
      fillDegree,
      compartmentUid,
      compartmentName,
      compartmentStatus,
      datetime,
      imageUid,
      productCount
    } = item
    const percentage = fillDegree ? Math.round(fillDegree * 100) : 100 //hack for invisible region click to view image
    const color = getChartColorByStatus(compartmentStatus)

    return {
      id: compartmentUid,
      name: compartmentName,
      percentage: percentage,
      emptyPercentage: 100 - percentage,
      percentageColor: fillDegree === 0 ? '#F2F8FF' : color,
      emptyPercentageColor: '#F2F8FF',
      borderColor: color,
      date: datetime || '',
      imageUid: imageUid,
      image: '',
      productCount: productCount,
      isEmpty: !fillDegree,
      status: compartmentStatus
    }
  })
