import * as ACT from './actions'
import { createReducer } from 'redux-create-reducer'
import * as filtersService from 'utils/filtersService'
import update from 'immutability-helper'
import moment from 'moment'

const initialState = {
  timeFilter: {
    selectedTimeFilterOption: 'today',
    startDate: moment(Date.now())
      .startOf('day')
      .toDate(),
    endDate: moment(Date.now()).toDate(),
    isTimeFilterActive: false
  },
  locationFilter: {
    regions: [],
    stores: [],
    isLocationFilterActive: false
  }
}

const localStorageState = () => ({
  timeFilter: {
    selectedTimeFilterOption: filtersService.getTimeFilterOption() || 'today',
    startDate:
      Date.parse(filtersService.getTimeFilterStartDate()) ||
      moment(Date.now())
        .subtract(1, 'weeks')
        .toDate(),
    endDate: Date.parse(filtersService.getTimeFilterEndDate()) || moment(Date.now()).toDate(),
    isTimeFilterActive: filtersService.getIsTimeFilterActive() || false
  },
  locationFilter: {
    regions: filtersService.getRegionFilterOption() || [],
    stores: filtersService.getStoreFilterOption() || [],
    isLocationFilterActive: filtersService.getIsLocationFilterActive() || false
  }
})

const filtersReducer = createReducer(initialState, {
  [ACT.INIT_FILTERS](state) {
    const { timeFilter, locationFilter } = localStorageState()

    return update(state, {
      timeFilter: {
        selectedTimeFilterOption: { $set: timeFilter.selectedTimeFilterOption },
        startDate: { $set: timeFilter.startDate },
        endDate: { $set: timeFilter.endDate },
        isTimeFilterActive: { $set: timeFilter.isTimeFilterActive }
      },
      locationFilter: {
        regions: { $set: locationFilter.regions },
        stores: { $set: locationFilter.stores },
        isLocationFilterActive: { $set: locationFilter.isLocationFilterActive }
      }
    })
  },

  [ACT.CANCEL_FILTERS](state) {
    return {
      ...state,
      ...localStorageState()
    }
  },

  [ACT.RESET_FILTERS]() {
    filtersService.removeTimeFilterOption()
    filtersService.removeTimeFilterStartDate()
    filtersService.removeTimeFilterEndDate()
    filtersService.removeIsTimeFilterActive()

    filtersService.removeRegionFilterOption()
    filtersService.removeStoreFilterOption()
    filtersService.removeIsLocationFilterActive()

    return {
      ...initialState
    }
  },

  [ACT.UPDATE_FILTERS](state, action) {
    const { selectedFilters } = action.payload
    const { timeFilter, locationFilter } = selectedFilters

    if (timeFilter.selectedTimeFilterOption) {
      filtersService.setTimeFilterOption(timeFilter.selectedTimeFilterOption)
      filtersService.setTimeFilterStartDate(timeFilter.startDate)
      filtersService.setTimeFilterEndDate(timeFilter.endDate)
      filtersService.setIsTimeFilterActive(timeFilter.isTimeFilterActive)
    }

    if (locationFilter.regions) {
      filtersService.setRegionFilterOption(locationFilter.regions)
      filtersService.setIsLocationFilterActive(locationFilter.isLocationFilterActive)
    }
    if (locationFilter.stores) filtersService.setStoreFilterOption(locationFilter.stores)

    return {
      ...state,
      ...selectedFilters
    }
  }
})

export default filtersReducer
