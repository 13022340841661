export const toReportTypes = reportTypes =>
  reportTypes.map(reportType => ({
    name: reportType.reportName,
    iconUrl: reportType.iconUrl,
    id: reportType.reportUid
  }))

export const toRequestFilters = filters => {
  const { department, dateIntervals, start, end, regionUids, locationUids } = filters

  let params = {
    departmentUid: department.departmentUid
  }

  if (dateIntervals) {
    params.dateIntervals = dateIntervals
  } else if (start && end) {
    params.dateStart = start
    params.dateEnd = end
  }

  if (locationUids.length) {
    params.regionUids = []
    params.locationUids = locationUids
  } else {
    if (regionUids.length) {
      const index = regionUids.length - 1
      params.regionUids = [regionUids[index]]
    } else {
      params.regionUids = []
    }

    params.locationUids = []
  }

  return params
}
