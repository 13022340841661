const MenuIcon = ({ menuVisible }) => {
  return (
    <svg
      viewBox="0 0 36 36"
      style={{ enableBackground: 'new 0 0 36 36' }}
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        className="menu-line menu-line-1"
        x={6}
        y={17}
        width={24}
        height={2}
        transform={menuVisible ? 'matrix(0.5,0,0,1,3,-8)' : 'matrix(0.5,0,0,1,15,-8)'}
        style={{ transformOrigin: '0px 0px' }}
      />
      <rect
        className="menu-line menu-line-2"
        x={6}
        y={17}
        width={24}
        height={2}
        transform={menuVisible ? 'matrix(0,0,0,1,6,0)' : 'matrix(1,0,0,1,0,0)'}
        style={{ transformOrigin: '0px 0px' }}
      />
      <rect
        className="menu-line menu-line-4"
        x={6}
        y={17}
        width={24}
        height={2}
        transform={menuVisible ? 'matrix(1,0,0,1,0,0)' : 'matrix(0,0,0,1,30,0)'}
        style={{ transformOrigin: '0px 0px' }}
      />
      <rect
        className="menu-line menu-line-3"
        x={6}
        y={17}
        width={24}
        height={2}
        transform={menuVisible ? 'matrix(0.5,0,0,1,15,8)' : 'matrix(0.5,0,0,1,3,8)'}
        style={{ transformOrigin: '0px 0px' }}
      />
    </svg>
  )
}

export default MenuIcon
