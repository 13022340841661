import { useState } from 'react'
import { setAcceptCookies } from 'utils/helpers'
import { Button } from 'components/rbc-core-ui'
import cn from 'classnames'
import s from './CookieSettings.scss'

const CookieSettings = ({ setAreCookiesAccepted }) => {
  const [showCookiesWarning, setShowCookiesWarning] = useState(false)

  const acceptCookies = () => {
    setAcceptCookies(true)
    setAreCookiesAccepted(true)
  }

  const rejectCookies = () => {
    setShowCookiesWarning(true)
  }

  return showCookiesWarning ? (
    <main className={s.wrapper}>
      <section className={cn(s.pageScreen, s.pageCookie)}>
        <div className={s.sectionContent}>
          <p className={s.description}>
            You need to accept cookies in order to proceed to the application. Please reload the
            page to start over.
          </p>
        </div>
      </section>
    </main>
  ) : (
    <main className={s.wrapper}>
      <section className={cn(s.pageScreen, s.pageCookie)}>
        <div className={s.sectionContent}>
          <h3>Cookie settings</h3>
          <h6>Manage Your Cookies</h6>
          <p className={s.description}>
            By clicking “Accept”, you agree to the use of analytical cookies and similar technologie
            (used to gain insight on app usage and to improve our services) and tracking
            technologies (both from SmartYourShelf.com and other trusted partners) that help decide
            which products to show you on and off our site and app, measure the audience visiting
            our app and website.
          </p>
          <p className={s.center}>
            <Button text="Decline" onClickCb={rejectCookies} className={s.cancel} />
            <Button
              text="Accept"
              onClickCb={acceptCookies}
              withIcon
              iconClassName="icon-arrow-next-1"
            />
          </p>
        </div>
      </section>
    </main>
  )
}

export default CookieSettings
