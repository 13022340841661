import s from './DepartmentFilters.scss'

const DepartmentFilters = () => {
  return (
    <div className={s.filterListContent}>
      <ul className={s.categoryList}>
        <li>
          <input type="radio" name="departments" />
          <label>
            <i className="icon-category-frozen-bakery"></i>
            <strong>Frozen Backery</strong>
          </label>
        </li>
        <li>
          <input type="radio" name="departments" />
          <label>
            <i className="icon-category-frozen-vegetables"></i>
            <strong>Frozen Vegetables</strong>
          </label>
        </li>
        <li>
          <input type="radio" name="departments" />
          <label>
            <i className="icon-category-frozen-sea-food"></i>
            <strong>Frozen Sea Food</strong>
          </label>
        </li>
        <li>
          <input type="radio" name="departments" />
          <label>
            <i className="icon-category-smoked-meat"></i>
            <strong>Smoked Meat</strong>
          </label>
        </li>
        <li>
          <input type="radio" name="departments" />
          <label>
            <i className="icon-category-fresh-fruits"></i>
            <strong>Fresh Fruits</strong>
          </label>
        </li>
      </ul>
    </div>
  )
}

export default DepartmentFilters
