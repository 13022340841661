import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { URLS } from 'utils/constants'
import { MenuIcon } from './MenuIcon'
import { MainMenu } from './MainMenu'
import s from './HeaderMenu.scss'
import cn from 'classnames'

import { FiltersContext } from '../../Layout/LayoutContainer'

const HeaderMenu = ({ toggleMenu, menuVisible }) => {
  const { viewFilters } = useContext(FiltersContext)
  return (
    <>
      <header
        className={cn(s.header, { [s.menuVisible]: menuVisible }, { [s.viewFilters]: viewFilters })}
      >
        <div className={s.headerWrapperContent}>
          <Link className={s.logo} to={URLS.AUTH_HOME} />
          <div className={s.menuBtn} onClick={toggleMenu}>
            <MenuIcon menuVisible={menuVisible} />
          </div>
        </div>
      </header>
      <MainMenu visible={menuVisible} />
    </>
  )
}

export default HeaderMenu
