import { StatusCharts } from './StatusCharts'
import { Indicators } from './Indicators'
import { Report } from '../common/Report'
import s from './Analytics.scss'

const Analytics = ({
  filters,
  requestFilters,
  isReportVisible,
  openReport,
  closeReport,
  selectedReportType,
  selectReportType,
  reportTypes,
  pieChartData,
  barChartData,
  drawers,
  indicators,
  showError,
  showFilters,
  setShowFilters
}) => {
  return showError ? (
    <div className={s.error}>No data available for the selected filters.</div>
  ) : (
    <>
      <StatusCharts pieChartData={pieChartData} barChartData={barChartData} drawers={drawers} />
      <Indicators
        indicators={indicators}
        selectReportType={selectReportType}
        reportTypes={reportTypes}
        openReport={openReport}
      />
      {selectedReportType && (
        <Report
          isVisible={isReportVisible}
          closeReport={closeReport}
          selectedReport={selectedReportType}
          filters={filters}
          requestFilters={requestFilters}
          showFilters={showFilters}
          setShowFilters={setShowFilters}
        />
      )}
    </>
  )
}

export default Analytics
