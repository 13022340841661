export const getTrendPercentage = (currentValue, previousValue) => {
  if (previousValue == 0) {
    return Math.round(currentValue * 100)
  }

  return Math.round(((currentValue - previousValue) / previousValue) * 100)
}

export const getTrend = (trendPercentage, largerBetter) => {
  if (trendPercentage === 0) return 'even'

  if (largerBetter) {
    return trendPercentage > 0 ? 'up' : 'down'
  } else {
    return trendPercentage < 0 ? 'up' : 'down'
  }
}
