import { useState } from 'react'
import { setLanguageOption } from './helpers'
import cn from 'classnames'
import { FaArrowRight } from 'react-icons/fa'
import s from './LanguageSelect.scss'

const LanguageSelect = ({ setLanguage }) => {
  const language = {
    value: 'EN',
    label: 'EN'
  }
  const [seletedLanguage, setSelectedLanguage] = useState(language)
  const languages = [
    {
      value: 'RO',
      label: 'RO'
    },
    {
      value: 'EN',
      label: 'EN'
    },
    {
      value: 'DE',
      label: 'DE'
    }
  ]
  const selectLanguage = () => {
    setLanguageOption(seletedLanguage.value)
    setLanguage(seletedLanguage)
  }

  return (
    <div className="bluePageTpl">
      <section className={cn(s.languageSelectorScreen, s.afterSplashScreen)}>
        <div className={s.sectionContent}>
          <h3>
            <span>Welcome to</span>Smart Your Shelf
          </h3>
          <h6>Choose Your Language</h6>
          <div className={s.chooseLanguageSlider}>
            <div className={s.swiperWrapper}>
              {languages.map(lang => (
                <div
                  key={lang.value}
                  className={cn(s.swiperSlide, {
                    [s.swiperSlideActive]: lang.value === seletedLanguage.value
                  })}
                  onClick={() => setSelectedLanguage(lang)}
                >
                  <span>{lang.label}</span>
                </div>
              ))}
            </div>
            <div className={s.swiperButtonPrev}></div>
            <div className={s.swiperButtonNext}></div>
          </div>
          <p className={s.description}>
            Your language preference can be changed at any time in Settings.
          </p>
          <p className={s.center}>
            <span className={cn(s.button, s.withIcon)} onClick={() => selectLanguage()}>
              Continue <FaArrowRight />
            </span>
          </p>
        </div>
      </section>
    </div>
  )
}

export default LanguageSelect
