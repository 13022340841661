import MaterialTable from '@material-table/core'

const ReportTable = ({ style, report }) => {
  return (
    <MaterialTable
      columns={report.columns}
      data={report.data}
      options={{
        sorting: false,
        toolbar: false,
        pageSize: 10,
        paging: false,
        headerStyle: style.headerStyle,
        cellStyle: style.cellStyle
      }}
      style={{
        boxShadow: 'none',
        fontSize: '12px'
      }}
    />
  )
}

export default ReportTable
