export const setTimeFilterOption = value => localStorage.setItem('timeFilterOption', value)
export const getTimeFilterOption = () => localStorage.getItem('timeFilterOption')
export const removeTimeFilterOption = () => localStorage.removeItem('timeFilterOption')

export const setTimeFilterStartDate = value => localStorage.setItem('timeFilterStartDate', value)
export const getTimeFilterStartDate = () => localStorage.getItem('timeFilterStartDate')
export const removeTimeFilterStartDate = () => localStorage.removeItem('timeFilterStartDate')

export const setTimeFilterEndDate = value => localStorage.setItem('timeFilterEndDate', value)
export const getTimeFilterEndDate = () => localStorage.getItem('timeFilterEndDate')
export const removeTimeFilterEndDate = () => localStorage.removeItem('timeFilterEndDate')

export const setIsTimeFilterActive = value => localStorage.setItem('isTimeFilterActive', value)
export const getIsTimeFilterActive = () => localStorage.getItem('isTimeFilterActive')
export const removeIsTimeFilterActive = () => localStorage.removeItem('isTimeFilterActive')

export const setLocationFilterOption = value => localStorage.setItem('locationFilterOption', value)
export const getLocationFilterOption = () => localStorage.getItem('locationFilterOption')
export const removeLocationFilterOption = () => localStorage.removeItem('locationFilterOption')

export const setRegionFilterOption = values =>
  localStorage.setItem('regionFilterOption', JSON.stringify(values))
export const getRegionFilterOption = () => JSON.parse(localStorage.getItem('regionFilterOption'))
export const removeRegionFilterOption = () => localStorage.removeItem('regionFilterOption')

export const setStoreFilterOption = values =>
  localStorage.setItem('storeFilterOption', JSON.stringify(values))
export const getStoreFilterOption = () => JSON.parse(localStorage.getItem('storeFilterOption'))
export const removeStoreFilterOption = () => localStorage.removeItem('storeFilterOption')

export const setIsLocationFilterActive = value =>
  localStorage.setItem('isLocationFilterActive', value)
export const getIsLocationFilterActive = () => localStorage.getItem('isLocationFilterActive')
export const removeIsLocationFilterActive = () => localStorage.removeItem('isLocationFilterActive')

export const setDepartmentFilterOption = value =>
  localStorage.setItem('departmentFilterOption', value)
export const getDepartmentFilterOption = () => localStorage.getItem('departmentFilterOption')
export const removeDepartmentFilterOption = () => localStorage.removeItem('departmentFilterOption')
