import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper'
import Modal from '@mui/material/Modal'
import { Drawers } from './Drawers'
import cn from 'classnames'
import groupBy from 'lodash/groupBy'
import { getDrawersColor } from './helpers'
import { capitalize } from 'utils/helpers'
import s from './DrawersSlider.scss'

const DrawersSlider = ({ initialSlide, drawers, isVisible, close }) => {
  const drawerList = groupBy(drawers, 'status')
  const statuses = Object.keys(drawerList)

  return (
    <Modal
      open={isVisible}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <section className={s.drawersSliderContainer}>
        <div className={s.closeBox} onClick={close}>
          <i className="icon-close" />
        </div>
        <Swiper
          className={cn(s.drawersSlider, 'drawers-slider')}
          modules={[Navigation, Pagination]}
          speed={700}
          slidesPerView={1}
          spaceBetween={16}
          pagination={{
            // el: '.swiper-pagination',
            clickable: true
          }}
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
          }}
          initialSlide={initialSlide}
        >
          {statuses.map(status => {
            const color = getDrawersColor(status)
            return (
              <SwiperSlide key={status} className={cn(s.swiperSlide, s[color])}>
                <Drawers label={capitalize(status)} color={color} drawers={drawerList[status]} />
              </SwiperSlide>
            )
          })}
        </Swiper>
      </section>
    </Modal>
  )
}

export default DrawersSlider
