import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { HeaderContainer } from './Header'
import ReactLoading from 'react-loading'
import s from './Layout.scss'
import { ErrorBoundary } from 'components/rbc-core-ui'
import { useMsal } from '@azure/msal-react'
import { InteractionRequiredAuthError } from '@azure/msal-browser'
import { loginRequest } from 'utils/authConfig'
import { authenticate } from 'utils/authService'
import { getTokenExpirationDate, isTokenExpired } from 'utils/jwtHelper'
import { toPageTitle } from './serializers'

export const SwiperContext = React.createContext()
export const FiltersContext = React.createContext()

const LayoutContainer = ({ children }) => {
  const [isFetching, setIsFetching] = useState(false)
  const [thumbsSwiper, setThumbsSwiper] = useState(null)
  const [isMenuVisible, setIsMenuVisible] = useState(false)
  const [viewFilters, setViewFilters] = useState(false)
  const [showHeader, setShowHeader] = useState(false)

  const pageTitle = useSelector(state => state.app.pageTitle)

  let location = useLocation()

  const { instance, accounts } = useMsal()

  const toggleMenu = () => {
    setIsMenuVisible(!isMenuVisible)
  }

  useEffect(() => {
    setShowHeader(location.pathname.includes('dashboard'))
  }, [location])

  useEffect(() => {
    document.title = toPageTitle(pageTitle)
  }, [pageTitle])

  useEffect(() => {
    setIsFetching(true)
    ;(async () => {
      let tokenResponse
      try {
        tokenResponse = await instance.acquireTokenSilent({
          ...loginRequest,
          account: accounts[0]
        })
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          // fallback to interaction when silent call fails
          tokenResponse = instance.acquireTokenRedirect({
            ...loginRequest,
            account: accounts[0]
          })
        }
      }

      if (isTokenExpired(tokenResponse.idToken)) {
        tokenResponse = await instance.acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
          forceRefresh: true
        })
      }

      // eslint-disable-next-line no-console
      console.log(tokenResponse.idToken)
      // eslint-disable-next-line no-console
      console.log(getTokenExpirationDate(tokenResponse.idToken))
      // eslint-disable-next-line no-console
      console.log('token is expired: ' + isTokenExpired(tokenResponse.idToken))

      authenticate(tokenResponse.idToken)
      setIsFetching(false)
    })()
  }, [accounts, instance, location])

  return (
    <FiltersContext.Provider
      value={{
        viewFilters: viewFilters,
        setViewFilters
      }}
    >
      <SwiperContext.Provider
        value={{
          thumbsSwiper: thumbsSwiper,
          setThumbsSwiper: setThumbsSwiper
        }}
      >
        {showHeader && (
          <HeaderContainer
            className={s.headerContainer}
            toggleMenu={toggleMenu}
            menuVisible={isMenuVisible}
          />
        )}
        <ErrorBoundary>
          {!isFetching && (
            <div
              style={{
                height: '100%',
                opacity: isMenuVisible ? 0 : 1,
                visibility: isMenuVisible ? 'hidden' : 'visible'
              }}
            >
              {children}
            </div>
          )}
        </ErrorBoundary>
        {isFetching && (
          <div className={s.loadingContainer}>
            <div className={s.loadingDiv}>
              <ReactLoading type="spinningBubbles" />
            </div>
          </div>
        )}
      </SwiperContext.Provider>
    </FiltersContext.Provider>
  )
}

export default LayoutContainer
