import moment from 'moment'

const getTimeFilterDates = timeFilter => {
  const { startDate, endDate, selectedTimeFilterOption } = timeFilter

  const daysDifference = moment(endDate).diff(moment(startDate), 'days')

  switch (selectedTimeFilterOption) {
    case 'today':
      return {
        firstIntervalStart: moment(Date.now())
          .startOf('day')
          .subtract(1, 'days'),
        firstIntervalEnd: moment(Date.now()).subtract(1, 'days'),
        secondIntervalStart: moment(Date.now()).startOf('day'),
        secondIntervalEnd: moment(Date.now())
      }
    case 'yesterday':
      return {
        firstIntervalStart: moment(Date.now())
          .startOf('day')
          .subtract(2, 'days'),
        firstIntervalEnd: moment(Date.now())
          .endOf('day')
          .subtract(2, 'days'),
        secondIntervalStart: moment(Date.now())
          .startOf('day')
          .subtract(1, 'days'),
        secondIntervalEnd: moment(Date.now())
          .endOf('day')
          .subtract(1, 'days')
      }
    case 'lastWeek':
      return {
        firstIntervalStart: moment(Date.now()).subtract(2, 'weeks'),
        firstIntervalEnd: moment(Date.now()).subtract(1, 'weeks'),
        secondIntervalStart: moment(Date.now()).subtract(1, 'weeks'),
        secondIntervalEnd: moment(Date.now())
      }
    case 'lastMonth':
      return {
        firstIntervalStart: moment(Date.now()).subtract(2, 'months'),
        firstIntervalEnd: moment(Date.now()).subtract(1, 'months'),
        secondIntervalStart: moment(Date.now()).subtract(1, 'months'),
        secondIntervalEnd: moment(Date.now())
      }
    case 'custom':
      return {
        firstIntervalStart: moment(startDate).subtract(daysDifference, 'days'),
        firstIntervalEnd: moment(endDate).subtract(daysDifference, 'days'),
        secondIntervalStart: moment(startDate),
        secondIntervalEnd: moment(endDate)
      }
  }
}

export const getFiltersForRequests = (timeFilter, locationFilter) => {
  const {
    firstIntervalStart,
    firstIntervalEnd,
    secondIntervalStart,
    secondIntervalEnd
  } = getTimeFilterDates(timeFilter)

  const dateIntervals = [
    {
      start: firstIntervalStart.format('YYYY-MM-DDTHH:mm:ss[Z]'),
      end: firstIntervalEnd.format('YYYY-MM-DDTHH:mm:ss[Z]')
    },
    {
      start: secondIntervalStart.format('YYYY-MM-DDTHH:mm:ss[Z]'),
      end: secondIntervalEnd.format('YYYY-MM-DDTHH:mm:ss[Z]')
    }
  ]

  const regionUids =
    locationFilter.isLocationFilterActive === 'true'
      ? locationFilter.regions.map(region => region.value)
      : []
  const locationUids =
    locationFilter.isLocationFilterActive === 'true'
      ? locationFilter.stores.map(store => store.value)
      : []

  return { dateIntervals, regionUids, locationUids }
}
